import { useState } from 'react';

import { useResgateCollection } from '@/common/presentation/hooks/useResgateCollection.ts';
import { useUsecase } from '@/common/presentation/hooks/useUsecase.ts';
import { type ContactResgateModel } from '@/features/contactbook/data/models/ContactResgateModel.ts';
import { DeleteContactUsecase } from '@/features/contactbook/domain/usecases/DeleteContactUsecase.ts';
import { GetContactsUsecase } from '@/features/contactbook/domain/usecases/GetContactsUsecase.ts';
import { mockedContactBook } from '@/features/contactbook/presentation/mocks/mockedContactBook.ts';

export const ContactList = () => {
  const getContactsUsecase = useUsecase(GetContactsUsecase);
  const deleteContactUsecase = useUsecase(DeleteContactUsecase);
  const [successMessage, setSuccessMessage] = useState<string | null>(null);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const { isLoading, models } = useResgateCollection<ContactResgateModel>(
    'contactQuery',
    getContactsUsecase,
    mockedContactBook,
  );

  const onDeleteContact = async (contactId: string) => {
    try {
      await deleteContactUsecase.call(contactId);
      setSuccessMessage('Contact deleted successfully!');
    } catch (error) {
      console.error('Failed to delete contact', error);
      setErrorMessage('Failed to delete contact');
    }
  };

  return (
    <div className="max-w-md rounded-md bg-white p-4">
      <h1 className="mb-4 text-2xl font-bold">Contact List</h1>
      {successMessage && <p className="text-green-500">{successMessage}</p>}
      {errorMessage && <p className="text-red-500">{errorMessage}</p>}
      {isLoading && <div>insert loading state here</div>}
      {!isLoading && models.length === 0 && <div>No contacts, insert empty state here</div>}
      {!isLoading &&
        models.length > 0 &&
        models?.map((contact) => (
          <div key={contact.id} className="mb-4 rounded-md border border-gray-300 p-4">
            <p className="text-lg font-semibold">
              {contact.given_name} {contact.family_name}
            </p>
            <p className="text-gray-600">{contact.company_name}</p>
            <div className="mt-2">
              <h3 className="text-md font-semibold">Phone Numbers:</h3>
              {contact.phone_numbers.length > 0 ? (
                <ul className="list-inside list-disc">
                  {contact.phone_numbers.map((phone, index) => (
                    <li key={index} className="text-gray-600">
                      {phone.phone_number_flat}
                    </li>
                  ))}
                </ul>
              ) : (
                <p className="text-gray-600">No phone numbers available</p>
              )}
            </div>
            <button
              className="mt-2 rounded bg-red-500 px-4 py-2 text-white"
              onClick={async () => onDeleteContact(contact.id)}
            >
              Delete
            </button>
          </div>
        ))}
    </div>
  );
};
