import { inject, injectable } from 'inversify';

import { TranslationRepository } from '@/features/translations/data/repository/TranslationRepository.ts';

@injectable()
export class TranslateUsecase {
  constructor(@inject(TranslationRepository) private translationRepository: TranslationRepository) {}

  call(key: string, params?: Record<string, string>) {
    return this.translationRepository.translate(key, params);
  }
}
