import { injectable } from 'inversify';
import { create } from 'zustand';
import { persist } from 'zustand/middleware';

import { Store } from '@/common/stores/Store.ts';

type State = {
  firstName: string;
  lastName: string;
  clientName: string;
  clientUuid: string;
  uuid: string;
  email: string;
  token: string;
  isAuthenticated: boolean;
};

@injectable()
export class AuthStore extends Store<State> {
  store = create<State>()(
    persist<State>(
      () => ({
        firstName: '',
        lastName: '',
        clientName: '',
        clientUuid: '',
        uuid: '',
        email: '',
        token: '',
        isAuthenticated: false,
      }),
      {
        name: 'auth',
      },
    ),
  );
}
