import { injectable } from 'inversify';

export type PushNotificationOptions = NotificationOptions & {
  title: string;
};

/**
 * Pushes an OS-level notification to the user's device.
 */

@injectable()
export class PushNotificationUsecase {
  async call({ title, ...options }: PushNotificationOptions) {
    return Notification.requestPermission(async (result) => {
      if (result !== 'granted') return;

      return navigator.serviceWorker.ready.then(async (registration) => {
        return registration.showNotification(title, { icon: 'logos/logo-192.png', ...options });
      });
    });
  }
}
