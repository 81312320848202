import { inject, injectable } from 'inversify';

import { CallingRepository } from '@/features/calling/data/repositories/CallingRepository.ts';

@injectable()
export class ToggleOnHoldUsecase {
  constructor(@inject(CallingRepository) private callingRepository: CallingRepository) {}

  call() {
    if (this.callingRepository.isOnHold()) {
      this.callingRepository.unhold();
    } else {
      this.callingRepository.hold();
    }
  }
}
