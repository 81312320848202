import { inject, injectable } from 'inversify';

import { Resgate } from '@/common/data/api/Resgate.ts';
import { AuthStore } from '@/features/auth/data/stores/AuthStore.ts';
import { transformContactModelToContactRequestModel } from '@/features/contactbook/data/adapters/transformContactModelToContactRequestModel.ts';
import { ContactBookApi } from '@/features/contactbook/data/api/ContactBookApi.ts';
import { type ContactModel } from '@/features/contactbook/data/models/ContactModel.ts';

@injectable()
export class ContactBookRepository {
  constructor(
    @inject(AuthStore) private authStore: AuthStore,
    @inject(ContactBookApi) private contactBookApi: ContactBookApi,
    @inject(Resgate) private resgate: Resgate,
  ) {}

  async getContact(contactId: string) {
    const { clientUuid } = this.authStore.getState();

    return await this.contactBookApi.getContact(clientUuid, contactId).then((response) => {
      return response.data;
    });
  }

  async getContacts() {
    // TODO: Change the subject to `contactbook.client.${clientUuid}` after contactbook has implemented Resgate: https://voipgrid.atlassian.net/browse/CI-863
    // const { clientUuid } = this.authStore.getState();
    return await this.resgate.getCollection(`contactbook.client.1`);
  }

  async createContact(data: ContactModel) {
    const { clientUuid } = this.authStore.getState();

    const requestData = transformContactModelToContactRequestModel(data);

    return await this.contactBookApi.createContact(clientUuid, requestData).then((response) => {
      return response.data;
    });
  }

  async updateContact(contactId: string, data: ContactModel) {
    const { clientUuid } = this.authStore.getState();

    const requestData = transformContactModelToContactRequestModel(data);

    return await this.contactBookApi.updateContact(clientUuid, contactId, requestData).then((response) => {
      return response.data;
    });
  }

  async deleteContact(contactUuid: string) {
    const { clientUuid } = this.authStore.getState();

    await this.contactBookApi.deleteContact(clientUuid, contactUuid);
  }
}
