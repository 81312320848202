import { faAddressBook, faMailboxFlagUp, faPhoneRotary } from '@awesome.me/kit-368b0118d2/icons/classic/regular';
import { useEffect } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';

import { Header } from '@/common/presentation/components/Header.tsx';
import { SideBar } from '@/common/presentation/components/SideBar.tsx';
import { useUsecase } from '@/common/presentation/hooks/useUsecase.ts';
import { GetAuthStoreUsecase } from '@/features/auth/domain/usecases/GetAuthStoreUsecase.ts';
import { TranslateUsecase } from '@/features/translations/domain/usecases/TranslateUsecase.ts';

/**
 * The ProtectedRoutes function component is used to protect certain routes that require user authentication.
 * It checks if the user is authenticated and then either renders the route or redirects to the login page.
 */
export const ProtectedRoutes = () => {
  const navigate = useNavigate();
  const authStore = useUsecase(GetAuthStoreUsecase).call();
  const translate = useUsecase(TranslateUsecase);
  const { isAuthenticated } = authStore();

  useEffect(() => {
    if (!isAuthenticated) {
      navigate('/login');
    }
  }, [isAuthenticated, navigate]);

  return (
    <div className="grid-container-small md:grid-container-large">
      <SideBar
        links={[
          {
            to: '/inbox',
            icon: faMailboxFlagUp,
            content: translate.call('inbox_page'),
          },
          {
            to: '/calling',
            icon: faPhoneRotary,
            content: translate.call('calling_page'),
          },
          {
            to: '/contactbook',
            icon: faAddressBook,
            content: translate.call('contactbook_page'),
          },
        ]}
      />
      <Header />
      <Outlet />
    </div>
  );
};
