import { useDebounce } from '@uidotdev/usehooks';
import { useEffect, useState } from 'react';

import { useUsecase } from '@/common/presentation/hooks/useUsecase.ts';
import { DTMF_DEBOUNCE_TIME } from '@/features/calling/domain/constants/DtmfConstants.ts';
import { SendDtmfUsecase } from '@/features/calling/domain/usecases/SendDtmfUsecase.ts';

/**
 * Hook to send DTMF signals with a debounce so that the buffer of typed DTMF tones is only being sent after the users
 * stops typing for a while.
 *
 * @returns {Function} A function that accepts a DTMF signal (as a string) and adds it to the buffer.
 */
export const useSendDtmf = () => {
  const [dtmfBuffer, setDtmfBuffer] = useState('');
  const debouncedDtmfBuffer = useDebounce(dtmfBuffer, DTMF_DEBOUNCE_TIME);

  const sendDtmfUsecase = useUsecase(SendDtmfUsecase);

  useEffect(() => {
    if (debouncedDtmfBuffer) {
      sendDtmfUsecase.call(debouncedDtmfBuffer);
      setDtmfBuffer('');
    }
  }, [debouncedDtmfBuffer]);

  return (key: string) => {
    setDtmfBuffer((prev) => prev + key);
  };
};
