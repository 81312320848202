import { EventEmitter } from 'eventemitter3';
import { Container, decorate, injectable } from 'inversify';

import { Resgate } from '@/common/data/api/Resgate.ts';
import { VoipgridApi } from '@/common/data/api/VoipgridApi.ts';
import { ConnectToResgateUsecase } from '@/common/domain/usecases/ConnectToResgateUsecase.ts';
import { PushNotificationUsecase } from '@/common/domain/usecases/PushNotificationUsecase.ts';
import { FormatDateToDateString } from '@/common/presentation/usecases/FormatDateToDateString.ts';
import { FormatDateToRelativeDateTimeString } from '@/common/presentation/usecases/FormatDateToRelativeDateTimeString.ts';
import { AuthRepository } from '@/features/auth/data/repositories/AuthRepository.ts';
import { AuthStore } from '@/features/auth/data/stores/AuthStore.ts';
import { GetAuthStoreUsecase } from '@/features/auth/domain/usecases/GetAuthStoreUsecase.ts';
import { LoginUsecase } from '@/features/auth/domain/usecases/LoginUsecase.ts';
import { LogoutUsecase } from '@/features/auth/domain/usecases/LogoutUsecase.ts';
import { CallingRepository } from '@/features/calling/data/repositories/CallingRepository.ts';
import { SipRepository } from '@/features/calling/data/repositories/SipRepository.ts';
import { VoipAccountRepository } from '@/features/calling/data/repositories/VoipAccountRepository.ts';
import { EndCallUsecase } from '@/features/calling/domain/usecases/EndCallUsecase.ts';
import { ListenToCallEventsUsecase } from '@/features/calling/domain/usecases/ListenToCallEventsUsecase.ts';
import { MakeOutgoingCallUsecase } from '@/features/calling/domain/usecases/MakeOutgoingCallUsecase.ts';
import { SendDtmfUsecase } from '@/features/calling/domain/usecases/SendDtmfUsecase.ts';
import { StartSipConnectionUsecase } from '@/features/calling/domain/usecases/StartSipConnectionUsecase.ts';
import { StopSipConnectionUsecase } from '@/features/calling/domain/usecases/StopSipConnectionUsecase.ts';
import { ToggleMuteUsecase } from '@/features/calling/domain/usecases/ToggleMuteUsecase.ts';
import { ToggleOnHoldUsecase } from '@/features/calling/domain/usecases/ToggleOnHoldUsecase.ts';
import { ChatRepository } from '@/features/chat/data/repositories/ChatRepository.ts';
import { GetChatStoreUsecase } from '@/features/chat/domain/usecases/GetChatStoreUsecase.ts';
import { GetMessagesStoreUsecase } from '@/features/chat/domain/usecases/GetMessagesStoreUsecase.ts';
import { GetRoomMessageDraftsStoreUsecase } from '@/features/chat/domain/usecases/GetRoomMessageDraftsStoreUsecase.ts';
import { GetRoomUsecase } from '@/features/chat/domain/usecases/GetRoomUsecase.ts';
import { GetRoomsStoreUsecase } from '@/features/chat/domain/usecases/GetRoomsStoreUsecase.ts';
import { MarkMessageAsReadUsecase } from '@/features/chat/domain/usecases/MarkMessageAsReadUsecase.ts';
import { SendMessageUsecase } from '@/features/chat/domain/usecases/SendMessageUsecase.ts';
import { SendTypingEventUsecase } from '@/features/chat/domain/usecases/SendTypingEventUsecase.ts';
import { SetCurrentRoomIdUsecase } from '@/features/chat/domain/usecases/SetCurrentRoomIdUsecase.ts';
import { SetMessagesInStoreUsecase } from '@/features/chat/domain/usecases/SetMessagesInStoreUsecase.ts';
import { SetRoomMessageDraftUsecase } from '@/features/chat/domain/usecases/SetRoomMessageDraftUsecase.ts';
import { StartChatConnectionUsecase } from '@/features/chat/domain/usecases/StartChatConnectionUsecase.ts';
import { ContactBookApi } from '@/features/contactbook/data/api/ContactBookApi.ts';
import { ContactBookRepository } from '@/features/contactbook/data/repositories/ContactBookRepository.ts';
import { CreateContactUsecase } from '@/features/contactbook/domain/usecases/CreateContactUsecase.ts';
import { DeleteContactUsecase } from '@/features/contactbook/domain/usecases/DeleteContactUsecase.ts';
import { GetContactUsecase } from '@/features/contactbook/domain/usecases/GetContactUsecase.ts';
import { GetContactsUsecase } from '@/features/contactbook/domain/usecases/GetContactsUsecase.ts';
import { UpdateContactUsecase } from '@/features/contactbook/domain/usecases/UpdateContactUsecase.ts';
import { SegmentConnector } from '@/features/report/data/api/SegmentConnector.ts';
import { SentryConnector } from '@/features/report/data/api/SentryConnector.ts';
import { ReportRepository } from '@/features/report/data/repository/ReportRepository.ts';
import { ConnectToReportingUsecase } from '@/features/report/domain/usecases/ConnectToReportingUsecase.ts';
import { SetIdentityUsecase } from '@/features/report/domain/usecases/SetIdentityUsecase.ts';
import { TrackUserActionUsecase } from '@/features/report/domain/usecases/TrackUserActionUsecase.ts';
import { SearchSphereApi } from '@/features/search/data/api/holodeck/SearchSphereApi.ts';
import { SearchRepository } from '@/features/search/data/repositories/SearchRepository.ts';
import { GetChatSearchResultsUsecase } from '@/features/search/domain/usecases/GetChatSearchResultsUsecase.ts';
import { GetRoomSearchResultsUsecase } from '@/features/search/domain/usecases/GetRoomSearchResultsUsecase.ts';
import { WhatsAppBridgeApi } from '@/features/settings/data/api/WhatsAppBridgeApi.ts';
import { WhatsAppRepository } from '@/features/settings/data/repositories/WhatsAppRepository.ts';
import { WhatsAppAuthorizeRequestUsecase } from '@/features/settings/domain/usecases/WhatsAppAuthorizeUsecase.ts';
import { TranslationRepository } from '@/features/translations/data/repository/TranslationRepository.ts';
import { TranslateUsecase } from '@/features/translations/domain/usecases/TranslateUsecase.ts';

decorate(injectable(), EventEmitter);

// Create a new container instance which will be used to bind the usecases and repositories.
export const container = new Container({ defaultScope: 'Singleton' });

// common
container.bind(VoipgridApi).toSelf();
container.bind(SearchSphereApi).toSelf();
container.bind(PushNotificationUsecase).toSelf();
container.bind(FormatDateToDateString).toSelf();
container.bind(FormatDateToRelativeDateTimeString).toSelf();
container.bind(Resgate).toSelf();
container.bind(ConnectToResgateUsecase).toSelf();

// features/auth
container.bind(AuthStore).toSelf();
container.bind(AuthRepository).toSelf();
container.bind(GetAuthStoreUsecase).toSelf();
container.bind(LogoutUsecase).toSelf();
container.bind(LoginUsecase).toSelf();

// feature/calling
container.bind(EndCallUsecase).toSelf();
container.bind(ListenToCallEventsUsecase).toSelf();
container.bind(MakeOutgoingCallUsecase).toSelf();
container.bind(SipRepository).toSelf();
container.bind(SendDtmfUsecase).toSelf();
container.bind(StartSipConnectionUsecase).toSelf();
container.bind(StopSipConnectionUsecase).toSelf();
container.bind(ToggleMuteUsecase).toSelf();
container.bind(VoipAccountRepository).toSelf();
container.bind(CallingRepository).toSelf();
container.bind(ToggleOnHoldUsecase).toSelf();

// features/report
container.bind(SentryConnector).toSelf();
container.bind(SegmentConnector).toSelf();
container.bind(ReportRepository).toSelf();
container.bind(ConnectToReportingUsecase).toSelf();
container.bind(SetIdentityUsecase).toSelf();
container.bind(TrackUserActionUsecase).toSelf();

// features/chat
container.bind(ChatRepository).toSelf();
container.bind(GetChatStoreUsecase).toSelf();
container.bind(GetRoomUsecase).toSelf();
container.bind(SetMessagesInStoreUsecase).toSelf();
container.bind(GetRoomMessageDraftsStoreUsecase).toSelf();
container.bind(GetRoomsStoreUsecase).toSelf();
container.bind(MarkMessageAsReadUsecase).toSelf();
container.bind(SendMessageUsecase).toSelf();
container.bind(SendTypingEventUsecase).toSelf();
container.bind(SetCurrentRoomIdUsecase).toSelf();
container.bind(GetMessagesStoreUsecase).toSelf();
container.bind(SetRoomMessageDraftUsecase).toSelf();
container.bind(StartChatConnectionUsecase).toSelf();

//feature/contactbook
container.bind(ContactBookRepository).toSelf();
container.bind(ContactBookApi).toSelf();
container.bind(CreateContactUsecase).toSelf();
container.bind(DeleteContactUsecase).toSelf();
container.bind(GetContactUsecase).toSelf();
container.bind(GetContactsUsecase).toSelf();
container.bind(UpdateContactUsecase).toSelf();

// features/search
container.bind(SearchRepository).toSelf();
container.bind(GetChatSearchResultsUsecase).toSelf();
container.bind(GetRoomSearchResultsUsecase).toSelf();

// features/settings
container.bind(WhatsAppBridgeApi).toSelf();
container.bind(WhatsAppRepository).toSelf();
container.bind(WhatsAppAuthorizeRequestUsecase).toSelf();

// features/translations
container.bind(TranslationRepository).toSelf();
container.bind(TranslateUsecase).toSelf();
