import { inject, injectable } from 'inversify';

import { type ContactModel } from '@/features/contactbook/data/models/ContactModel.ts';
import { ContactBookRepository } from '@/features/contactbook/data/repositories/ContactBookRepository.ts';

@injectable()
export class CreateContactUsecase {
  constructor(@inject(ContactBookRepository) private contactBookRepository: ContactBookRepository) {}

  async call(data: ContactModel) {
    return await this.contactBookRepository.createContact(data);
  }
}
