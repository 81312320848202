import { EventEmitter } from 'eventemitter3';
import {
  ClientEvent,
  Direction,
  EventType,
  type MatrixClient,
  type MatrixEvent,
  type Room,
  RoomEvent,
  type RoomMember,
  RoomMemberEvent,
  SyncState,
  TimelineWindow,
  createClient,
} from 'matrix-js-sdk';

import { transformMatrixRoomMessageEventToMessageEntity } from '@/features/chat/data/adapters/transformMatrixRoomMessageEventToMessageEntity.ts';
import { type MessageType } from '@/features/chat/data/types/MessageType.ts';
import { type RoomMessages } from '@/features/chat/data/types/RoomMessages.ts';
import { type TimelineDirection } from '@/features/chat/data/types/TimelineDirection.ts';
import { type MessageEntity } from '@/features/chat/domain/entities/MessageEntity.ts';
import { type RoomEntity } from '@/features/chat/domain/entities/RoomEntity.ts';
import { isNewMessage } from '@/features/chat/utils/isNewMessage.ts';

const MATRIX_MESSAGE_LIMIT = 20;
const MATRIX_INITIAL_SYNC_LIMIT = 20;
const MATRIX_USER_TYPING_TIMEOUT = 3000;
const MATRIX_SERVER_TYPING_TIMEOUT = 20000;

type Events = {
  syncPrepared: () => void;
  roomMessageReceived: (event: MatrixEvent, matrixRoom: Room) => void;
  receiptUpdate: (roomId: string) => void;
  typingUpdate: (event: MatrixEvent, member: RoomMember) => void;
};

export class MatrixConnector extends EventEmitter<Events> {
  private client!: MatrixClient;
  /**
   * The timeline window is used to paginate through the messages in a room.
   * URL: https://matrix-org.github.io/matrix-js-sdk/classes/matrix.TimelineWindow.html
   */
  timelineWindow!: TimelineWindow;
  /**
   * We'll keep track of the current room ID to know when the room changes.
   */
  timelineRoomId!: string;
  /**
   * The isTyping boolean is used to keep track of the typing state of the user.
   * This is being done this to prevent spamming the Synapse server with typing events.
   */
  isTyping = false;
  /**
   * The isTypingNextPing number is being used to keep track of the next time we can send a typing event.
   */
  isTypingNextPing = 0;
  /**
   * The stoppedTypingTimeout is used to keep track of the timeout when the user stops typing.
   */
  stoppedTypingTimeout = 0;

  addClientListeners() {
    this.client.on(RoomEvent.Timeline, async (event) => {
      // On updates within the room timeline it is necessary to extend the timeline window.
      // URL: https://matrix-org.github.io/matrix-js-sdk/classes/matrix.TimelineWindow.html
      if (this.timelineWindow) {
        await this.timelineWindow.paginate(Direction.Forward, MATRIX_MESSAGE_LIMIT);
      }

      // We want to only emit the event when it's a new room message.
      if (isNewMessage(event)) {
        const roomId = this.getRoomIdFromMatrixEvent(event);
        const matrixRoom = this.getMatrixRoom(roomId);
        this.emit('roomMessageReceived', event, matrixRoom);
      }
    });

    this.client.on(RoomEvent.Receipt, (event, room) => {
      // When we've send a mark message as read event we get two RoomEvent.Receipt event back.
      // One for the room and one for the user. We only want to emit the event for the user Room Receipt event.
      if (!event.getRoomId()) {
        this.emit('receiptUpdate', room.roomId);
      }
    });

    this.client.on(RoomMemberEvent.Typing, (event, member) => {
      this.emit('typingUpdate', event, member);
    });
  }

  /**
   * Create a client and afterwards login to the Matrix service with the token we received from VG.
   * This token is used to authenticate the user with the Matrix service and connect to the right space.
   */
  async createClient(token: string) {
    if (!this.client) {
      this.client = createClient({
        baseUrl: import.meta.env.VITE_MATRIX_HOME_SERVER,
        timelineSupport: true,
      });
    }

    await this.client.login('nl.voys.single_user', {
      identifier: {
        type: 'm.id.user',
        user: 'voys_user',
      },
      initial_device_display_name: 'Conversations App',
      token,
    });

    const userId = this.getMatrixUserId();
    const accessToken = this.client.getAccessToken();

    return { userId, accessToken };
  }

  /**
   * Adds event listeners on events and then starts the Matrix client, so it can start polling for events.
   */
  async connect() {
    // If the client is already running we don't need to start it again.
    if (this.client.clientRunning) return;

    this.client.once(ClientEvent.Sync, (state) => {
      if (state === SyncState.Prepared) {
        this.addClientListeners();
        this.emit('syncPrepared');
      }
    });

    // TODO: Possibly listen to any errors which can occur during
    //  the startClient method and catch them / handle them.
    //  Instead of returning true we can then handle the error.
    await this.client.startClient({
      initialSyncLimit: MATRIX_INITIAL_SYNC_LIMIT,
    });

    // Workaround to stop the matrixRTC when the initial sync is complete.
    // This has to be placed after the client has started to remove the added event listeners.
    // We don't use RTC with matrix and it shows an error about room state:
    // "Got room state event for unknown room"
    // There is a GitHub issue about this: https://github.com/matrix-org/matrix-js-sdk/issues/3781
    // but it's not resolved yet.
    this.client.once(ClientEvent.Sync, (state) => {
      if (state === SyncState.Prepared) {
        this.client.matrixRTC.stop();
      }
    });
  }

  private getRoomIdFromMatrixEvent(event: MatrixEvent) {
    const roomId = event.getRoomId();

    if (!roomId) {
      throw new Error('Room ID not found');
    }

    return roomId;
  }

  private getMatrixRoom(roomId: string) {
    const room = this.client.getRoom(roomId);

    if (!room) {
      throw new Error(`Room with id ${roomId} not found`);
    }

    return room;
  }

  private getMatrixUserId() {
    const userId = this.client.getUserId();

    if (!userId) {
      throw new Error('User id not found');
    }

    return userId;
  }

  getUsernameByUserId(userId: string) {
    const user = this.client.getUser(userId);

    if (!user) {
      throw new Error(`User with id ${userId} not found`);
    }

    if (!user.displayName) {
      return user.userId;
    }

    return user.displayName;
  }

  getUsernameOfMatrixUser() {
    const userId = this.getMatrixUserId();
    return this.getUsernameByUserId(userId);
  }

  /**
   * Retrieves messages from a specific room.
   * This can be based on three different scenarios:
   * 1. Get the latest messages from the room.
   * 2. Get messages before and after a specific event.
   * 3. Get messages from a specific direction (through scrolling).
   */
  async getRoomMessages(room: RoomEntity, eventId = '', direction?: TimelineDirection): Promise<RoomMessages> {
    const matrixRoom = this.getMatrixRoom(room.roomId);
    const userId = this.getMatrixUserId();
    const isFirstLoad = !direction;

    // Create a new timeline window if the room changes or the
    // timeline doesn't exist yet.
    if (!this.timelineWindow || this.timelineRoomId !== room.roomId) {
      this.timelineWindow = new TimelineWindow(this.client, matrixRoom.getUnfilteredTimelineSet());
      this.timelineRoomId = room.roomId;
    }

    if (isFirstLoad) {
      // If we're loading the initial messages,
      // we want to load the latest messages or the messages
      // near a specific eventId.
      await this.timelineWindow.load(eventId, MATRIX_MESSAGE_LIMIT);
    } else {
      // If we're loading more messages, we want to paginate based
      // on the direction we're scrolling.
      const paginateDirection = direction === 'backwards' ? Direction.Backward : Direction.Forward;
      await this.timelineWindow.paginate(paginateDirection, MATRIX_MESSAGE_LIMIT);
    }

    const messages = this.timelineWindow
      .getEvents()
      .filter((event) => event.getType() === EventType.RoomMessage)
      .map((event) => transformMatrixRoomMessageEventToMessageEntity(event, matrixRoom, room, userId));

    return {
      messages,
      isInitialized: isFirstLoad,
      hasMoreBackwards: this.timelineWindow.canPaginate(Direction.Backward),
      hasMoreForwards: this.timelineWindow.canPaginate(Direction.Forward),
    };
  }

  /**
   * Get rooms from the Matrix Memory Store.
   */
  getRooms() {
    return this.client.getRooms();
  }

  /**
   * Send a message read event to the Matrix server to inform a specific message is read.
   */
  async markMessageAsRead(message: MessageEntity) {
    const messageEvent = this.timelineWindow.getEvents().find(({ event }) => event.event_id === message.eventId);

    if (!messageEvent) {
      throw new Error(`Message with id ${message.eventId} not found`);
    }
    await this.client.sendReadReceipt(messageEvent);
  }

  async sendMessage(type: MessageType, message: string, roomId: string) {
    switch (type) {
      case 'text':
        await this.client.sendTextMessage(roomId, message);
        break;
      case 'sticker':
        // Assuming content is a URL to the sticker image
        await this.client.sendStickerMessage(roomId, message, {});
        break;
      case 'image':
        // Assuming content is a URL to the image
        await this.client.sendImageMessage(roomId, message, {});
        break;
    }
  }

  async sendTypingEvent(roomId: string, isTyping: boolean) {
    // When the user explicitely stops typing, send the "stopped typing" event.
    if (!isTyping) {
      this.client.sendTyping(roomId, false, MATRIX_SERVER_TYPING_TIMEOUT);
      return;
    }

    // When the user starts typing, send the initial "typing" event.
    if (!this.isTyping) {
      this.client.sendTyping(roomId, true, MATRIX_SERVER_TYPING_TIMEOUT);
      this.isTyping = true;
      this.isTypingNextPing = Date.now() + MATRIX_USER_TYPING_TIMEOUT;
    }

    // Clear the timeout set below everytime the user types.
    if (this.stoppedTypingTimeout) {
      window.clearTimeout(this.stoppedTypingTimeout);
      this.stoppedTypingTimeout = 0;
    }

    // A timeout will be set (which is cleared above when typing)
    // We set this timeout so we can send a "stopped typing" event
    // if the user didn't type for 3 seconds.
    if (!this.stoppedTypingTimeout) {
      this.stoppedTypingTimeout = window.setTimeout(() => {
        this.client.sendTyping(roomId, false, MATRIX_SERVER_TYPING_TIMEOUT);
        this.isTyping = false;
        this.isTypingNextPing = 0;
        this.stoppedTypingTimeout = 0;
      }, MATRIX_USER_TYPING_TIMEOUT);
    }

    // If the user is still typing, keep sending typing events.
    // We limit this to every 3 seconds to prevent spamming the server.
    if (this.isTyping && Date.now() > this.isTypingNextPing) {
      this.client.sendTyping(roomId, true, MATRIX_SERVER_TYPING_TIMEOUT);
      this.isTypingNextPing = Date.now() + MATRIX_USER_TYPING_TIMEOUT;
    }
  }
}
