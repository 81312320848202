import { injectable } from 'inversify';
import { type StoreApi, type UseBoundStore } from 'zustand';

@injectable()
export abstract class Store<T> {
  abstract store: UseBoundStore<StoreApi<T>>;

  setState(state: Partial<T> | ((prev: T) => Partial<T>)) {
    this.store.setState(state);
  }

  getState() {
    return this.store.getState();
  }

  reset() {
    this.store.setState(this.store.getInitialState());
  }
}
