import { faCircleUser } from '@awesome.me/kit-368b0118d2/icons/classic/regular';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Skeleton from 'react-loading-skeleton';
import { Link } from 'react-router-dom';

import { useUsecase } from '@/common/presentation/hooks/useUsecase.ts';
import { FormatDateToRelativeDateTimeString } from '@/common/presentation/usecases/FormatDateToRelativeDateTimeString.ts';
import { highlightWords } from '@/features/search/presentation/utils/highlightWords.tsx';

type Props = {
  link: string;
  title: string;
  searchQuery: string;
  isLoaded: boolean;
  tag?: string;
  description?: string;
  timestamp?: number;
};

export const SearchResultItem = ({ link, title, searchQuery, isLoaded, tag, description, timestamp }: Props) => {
  const formatDateToRelativeDateTimeString = useUsecase(FormatDateToRelativeDateTimeString);

  return (
    <Link
      to={isLoaded ? link : `?q=${searchQuery}`}
      className="mb-2 flex w-full items-center rounded border border-neutral-200 bg-white p-4 shadow-sm hover:cursor-pointer hover:border-primary hover:bg-primary hover:bg-opacity-10"
    >
      <div className="relative mr-4 flex h-10 w-10 items-center justify-center rounded-xl rounded-tr-none bg-neutral-200">
        <FontAwesomeIcon icon={faCircleUser} className="size-4" />
      </div>
      <div className="flex flex-1 flex-col overflow-hidden">
        <div className="flex items-center">
          <span className="font-semibold">
            {isLoaded && title ? highlightWords(title, searchQuery) : <Skeleton width={400} />}
          </span>
          {isLoaded && tag && <span className="ml-1 text-xs text-neutral-700">@{tag}</span>}
        </div>
        <span className="truncate text-sm text-neutral-700">
          {description ? isLoaded ? highlightWords(description, searchQuery) : <Skeleton /> : null}
        </span>
      </div>
      <div className="ml-4 h-full">
        <span className="text-sm text-neutral-700">
          {timestamp ? (
            isLoaded ? (
              formatDateToRelativeDateTimeString.call(new Date(timestamp))
            ) : (
              <Skeleton width={40} />
            )
          ) : null}
        </span>
      </div>
    </Link>
  );
};
