import { inject, injectable } from 'inversify';

import { ChatRepository } from '@/features/chat/data/repositories/ChatRepository.ts';
import { type MessageType } from '@/features/chat/data/types/MessageType.ts';

@injectable()
export class SendMessageUsecase {
  constructor(@inject(ChatRepository) private chatRepository: ChatRepository) {}

  async call(type: MessageType, message: string, roomId: string) {
    await this.chatRepository.sendMessage(type, message, roomId);
  }
}
