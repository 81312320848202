import { ResCollectionMock } from '@/common/data/api/ResCollection.mock.ts';
import { type ContactModel } from '@/features/contactbook/data/models/ContactModel.ts';

const mockedResults = Array.from({ length: 10 }).map<ContactModel>((_, index) => {
  return {
    id: index.toString(),
    given_name: 'Mock',
    family_name: 'Mockedson',
    company_name: 'Mocked ltd',
    phone_numbers: [
      {
        phone_number_flat: 'Mocked phone number',
        phone_number_pretty: 'Mocked pretty phone number',
        country_code: 'Mocked country code',
      },
    ],
  };
});

export const mockedContactBook = new ResCollectionMock(mockedResults);
