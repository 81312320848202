import { inject, injectable } from 'inversify';

import { VoipgridApi } from '@/common/data/api/VoipgridApi.ts';

@injectable()
export class VoipAccountRepository {
  constructor(@inject(VoipgridApi) private voipgridApi: VoipgridApi) {}

  async getSelectedWebphoneVoipAccount() {
    return await this.voipgridApi.getSelectedWebphoneVoipAccount();
  }
}
