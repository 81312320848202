import { inject, injectable } from 'inversify';

import { Resgate } from '@/common/data/api/Resgate.ts';

@injectable()
export class ConnectToResgateUsecase {
  constructor(@inject(Resgate) private resgateClient: Resgate) {}

  call() {
    this.resgateClient.connect();
  }
}
