import { inject, injectable } from 'inversify';

import { WhatsAppBridgeApi } from '@/features/settings/data/api/WhatsAppBridgeApi.ts';
import { type WhatsAppAuthorizeRequest } from '@/features/settings/data/models/WhatsAppAuthorizeRequest.ts';

@injectable()
export class WhatsAppRepository {
  constructor(@inject(WhatsAppBridgeApi) private whatsAppBridgeApi: WhatsAppBridgeApi) {}

  async authorize(data: WhatsAppAuthorizeRequest) {
    return await this.whatsAppBridgeApi.authorize(data).then((response) => {
      return response.data;
    });
  }
}
