import { type TypingMember } from '@/features/chat/domain/entities/RoomEntity.ts';

/**
 * Returns the different use-cases for displaying if members
 * are typing in the chat.
 *
 * TODO: #AA-125 This functionality will probably change once the design
 * is finalized.
 */
export const createTypingMembersString = (members: Array<TypingMember>) => {
  const count = members.length;

  if (count === 0) return '';

  const mappedNames = members.map((member) => member.displayName);

  switch (count) {
    case 1:
      return `${mappedNames[0]} is typing...`;
    case 2:
      return `${mappedNames[0]} and ${mappedNames[1]} are typing...`;
    default: {
      const lastMember = mappedNames.pop();
      return `${mappedNames.join(', ')} and ${lastMember} are typing...`;
    }
  }
};
