import { Outlet } from 'react-router-dom';

import { useUsecase } from '@/common/presentation/hooks/useUsecase.ts';
import { GetChatStoreUsecase } from '@/features/chat/domain/usecases/GetChatStoreUsecase.ts';

/**
 * The ChatRoutes function component is used to first do a pre-check
 * if the chat store is initialized.
 */
export const ChatRoutes = () => {
  const getChatStoreUsecase = useUsecase(GetChatStoreUsecase);
  const chatStore = getChatStoreUsecase.call();
  const { isInitialized } = chatStore();

  if (!isInitialized) {
    return;
  }

  return <Outlet />;
};
