import { inject, injectable } from 'inversify';

import { ChatRepository } from '@/features/chat/data/repositories/ChatRepository.ts';

@injectable()
export class SetCurrentRoomIdUsecase {
  constructor(@inject(ChatRepository) private chatRepository: ChatRepository) {}

  call(roomId: string) {
    this.chatRepository.setCurrentRoomId(roomId);
  }
}
