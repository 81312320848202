import { type IconDefinition } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { NavLink } from 'react-router-dom';

import { cn } from '@/common/presentation/utils.ts';

type SidemenuItem = {
  label: string;
  href: string;
  icon: IconDefinition;
};

type SidemenuProps = {
  items: Array<SidemenuItem>;
};

export const Sidemenu = ({ items }: SidemenuProps) => {
  const linkClasses = (isActive: boolean) => {
    return cn(
      'focus-visible:ring-ring mb-1 inline-flex h-9 w-full items-center justify-start gap-2 whitespace-nowrap rounded-md px-4 py-2 text-base font-medium text-icon/neutral/fillColor/default transition-colors hover:bg-icon/neutral/fillColor/default hover:bg-opacity-10 focus-visible:outline-none focus-visible:ring-1 disabled:pointer-events-none disabled:opacity-50 group-hover/link:text-controls/neutral/iconFillColor/hover',
      isActive
        ? 'bg-primary-lightest text-primary hover:bg-primary-lightest hover:bg-opacity-100 hover:text-primary'
        : '',
    );
  };

  return (
    <aside className="w-64 rounded-md bg-layout/neutral/bgColor p-4 shadow">
      <nav>
        <ul className="w-full">
          {items.map((item) => (
            <li key={item.label}>
              <NavLink to={item.href} className={({ isActive }) => linkClasses(isActive)}>
                <FontAwesomeIcon icon={item.icon} className="pointer-events-none mr-2 size-4 shrink-0" />
                {item.label}
              </NavLink>
            </li>
          ))}
        </ul>
      </nav>
    </aside>
  );
};
