import { faPaperPlaneAlt } from '@awesome.me/kit-368b0118d2/icons/classic/regular';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { usePrevious } from '@uidotdev/usehooks';
import { type KeyboardEvent, useEffect, useRef } from 'react';

import { useUsecase } from '@/common/presentation/hooks/useUsecase.ts';
import { GetRoomMessageDraftsStoreUsecase } from '@/features/chat/domain/usecases/GetRoomMessageDraftsStoreUsecase.ts';
import { SendMessageUsecase } from '@/features/chat/domain/usecases/SendMessageUsecase.ts';
import { SendTypingEventUsecase } from '@/features/chat/domain/usecases/SendTypingEventUsecase.ts';
import { SetRoomMessageDraftUsecase } from '@/features/chat/domain/usecases/SetRoomMessageDraftUsecase.ts';
import { TranslateUsecase } from '@/features/translations/domain/usecases/TranslateUsecase.ts';

type Props = {
  roomId: string;
  messagesEndRef: React.RefObject<HTMLDivElement>;
};

export const ChatInput = ({ roomId, messagesEndRef }: Props) => {
  const getRoomMessageDraftsStoreUsecase = useUsecase(GetRoomMessageDraftsStoreUsecase);
  const sendMessageUsecase = useUsecase(SendMessageUsecase);
  const sendTypingEventUsecase = useUsecase(SendTypingEventUsecase);
  const setRoomMessageDraftUsecase = useUsecase(SetRoomMessageDraftUsecase);

  const draftsStore = getRoomMessageDraftsStoreUsecase.call();
  const { drafts } = draftsStore();
  const inputRef = useRef<HTMLTextAreaElement>(null);
  const previousRoomId = usePrevious(roomId);
  const translate = useUsecase(TranslateUsecase);

  // Use a ref instead of state to prevent re-render when the user types.
  const getInputText = () => {
    return inputRef.current?.value.trim() || '';
  };

  const setInputText = (text: string) => {
    if (inputRef.current) {
      inputRef.current.value = text;
      inputRef.current.style.height = 'auto'; // Temporarily shrink the textarea to let it adjust its height to its content
      inputRef.current.style.height = `${inputRef.current.scrollHeight}px`;
    }
  };

  const handleKeyDown = (event: KeyboardEvent<HTMLTextAreaElement>) => {
    if (event.key === 'Enter' && !event.shiftKey) {
      // Prevent the default behavior of adding a newline in the textarea when pressing Enter, users can use shift+enter instead.
      event.preventDefault();
      send();
    }
  };

  const handleInput = () => {
    // Make sure the input has text, this makes sure if you press backspace
    // when the input has no text, we don't send a typing event.
    const text = getInputText();
    if (!text) return;

    sendTypingEventUsecase.call(roomId, true);
  };

  // When switching rooms, we want to send a "stopped typing"
  // event for the previous room.
  useEffect(() => {
    if (previousRoomId) {
      sendTypingEventUsecase.call(previousRoomId, false);
    }
  }, [previousRoomId, sendTypingEventUsecase]);

  const send = async () => {
    if (!getInputText()) return;

    // Clear the input for the current roomId
    setRoomMessageDraftUsecase.call(roomId, '');
    const text = getInputText();
    setInputText('');

    await sendMessageUsecase.call('text', text, roomId);

    // Use the messageEndRef from ChatView to get the user back to the bottom of the chat
    messagesEndRef.current?.scrollIntoView({ behavior: 'instant' });
  };

  // Save the draft when the user switches rooms.
  useEffect(() => {
    if (previousRoomId) {
      setRoomMessageDraftUsecase.call(previousRoomId, getInputText());
    }
  }, [previousRoomId, setRoomMessageDraftUsecase]);

  // Load the draft when the user switches rooms.
  useEffect(() => {
    setInputText(drafts[roomId] || '');
  }, [roomId, drafts]);

  return (
    <div className="w-full bg-white py-4">
      <div className="relative flex items-center">
        <label htmlFor="chat-input" className="sr-only">
          {translate.call('message_placeholder')}
        </label>
        <textarea
          id="chat-input"
          onKeyDown={handleKeyDown}
          onInput={handleInput}
          ref={inputRef}
          rows={1}
          className="h-8 max-h-96 w-full resize-none rounded-md bg-gray-100 p-4 pr-[4.5rem] font-medium text-gray-900 shadow-inner"
          onChange={(e) => setInputText(e.target.value)}
          placeholder={translate.call('message_placeholder')}
        />
        <button
          onClick={send}
          aria-label={translate.call('send_message')}
          className="absolute right-2 top-2 size-10 rounded-md rounded-tr-none bg-primary transition duration-300 ease-in-out hover:bg-primary/80"
        >
          <FontAwesomeIcon icon={faPaperPlaneAlt} className="text-white" />
        </button>
      </div>
    </div>
  );
};
