import { faEllipsis } from '@awesome.me/kit-368b0118d2/icons/classic/regular';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import { type ReactNode, useState } from 'react';

import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuTrigger,
} from '@/common/presentation/components/DropdownMenu.tsx';
import { useUsecase } from '@/common/presentation/hooks/useUsecase.ts';
import { TranslateUsecase } from '@/features/translations/domain/usecases/TranslateUsecase.ts';

type Props = {
  direction: 'start' | 'end';
  children: ReactNode;
};

export const MessageMenu = ({ direction, children }: Props) => {
  const [isOpen, setIsOpen] = useState(false);
  const [isPointerDownOutside, setIsPointerDownOutside] = useState(false);
  const translate = useUsecase(TranslateUsecase);

  return (
    <DropdownMenu open={isOpen} onOpenChange={setIsOpen}>
      <DropdownMenuTrigger className="group">
        <div
          className={classNames(
            'flex size-4 items-center group-hover:visible group-focus:visible',
            isOpen ? 'visible' : 'invisible',
          )}
        >
          <span className="sr-only">{translate.call('open_message_options')}</span>
          <FontAwesomeIcon icon={faEllipsis} className="text-gray-400" />
        </div>
      </DropdownMenuTrigger>
      <DropdownMenuContent
        className={classNames(
          'absolute z-10 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none',
          {
            '-right-2': direction === 'end',
            '-left-2': direction === 'start',
          },
        )}
        onCloseAutoFocus={(e) => {
          // Only prevent autofocus if the user clicked outside the dropdown.
          if (isPointerDownOutside) {
            e.preventDefault();
            setIsPointerDownOutside(false);
          }
        }}
        onPointerDownOutside={() => {
          setIsPointerDownOutside(true);
        }}
      >
        {children}
      </DropdownMenuContent>
    </DropdownMenu>
  );
};
