import { inject, injectable } from 'inversify';

import { SipRepository } from '@/features/calling/data/repositories/SipRepository.ts';

@injectable()
export class StopSipConnectionUsecase {
  constructor(@inject(SipRepository) private sipRepository: SipRepository) {}

  call() {
    this.sipRepository.disconnect();
  }
}
