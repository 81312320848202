import { useIntersectionObserver } from '@uidotdev/usehooks';
import classNames from 'classnames';
import dayjs from 'dayjs';
import { useEffect } from 'react';

import { useUsecase } from '@/common/presentation/hooks/useUsecase.ts';
import { type MessageEntity } from '@/features/chat/domain/entities/MessageEntity.ts';
import { MarkMessageAsReadUsecase } from '@/features/chat/domain/usecases/MarkMessageAsReadUsecase.ts';
import { CopyTextMenuItem } from '@/features/chat/presentation/components/CopyTextMenuItem.tsx';
import { MessageMenu } from '@/features/chat/presentation/components/MessageMenu.tsx';

type Props = {
  message: MessageEntity;
  hasSameSenderAsPreviousMessage: boolean;
  userName: string;
  urlEventId?: string;
  inboxRef: React.RefObject<HTMLDivElement>;
};

export const Message = ({ message, hasSameSenderAsPreviousMessage, userName, urlEventId, inboxRef }: Props) => {
  const [ref, entry] = useIntersectionObserver({
    root: inboxRef.current,
    threshold: 1,
    rootMargin: '0px',
  });

  const markMessageAsReadUsecase = useUsecase(MarkMessageAsReadUsecase);

  useEffect(() => {
    const handleReadMessage = async () => {
      if (!entry?.isIntersecting || message.isRead) return;

      await markMessageAsReadUsecase.call(message);
    };

    handleReadMessage();
  }, [entry, markMessageAsReadUsecase, message]);

  const messagePositionClass = classNames(
    `mb-4 flex last:mb-0`,
    message.type === 'outgoing' && 'justify-end',
    message.type === 'incoming' && 'justify-start',
  );

  const messageClass = classNames(
    'flex max-w-96 flex-col break-words rounded-lg border px-3 py-2 shadow-sm',
    message.type === 'incoming' && 'rounded-tl-none border-gray-200 bg-white',
    message.type === 'outgoing' && 'rounded-tr-none border-primary/15 bg-primary/10',
    urlEventId === message.eventId && 'border-transparent outline outline-4 outline-primary',
  );

  return (
    <div ref={ref} className={messagePositionClass}>
      <div className="flex flex-col">
        {message.type === 'outgoing' && !hasSameSenderAsPreviousMessage && (
          <div className="mb-1 text-right text-sm font-semibold text-gray-700">{userName}</div>
        )}
        <div className={classNames('group', messageClass)}>
          <div className="flex justify-between gap-2">
            <div className="min-w-0 break-words text-sm">{message.message}</div>
            <div className="flex-1">
              <MessageMenu direction={message.type === 'incoming' ? 'start' : 'end'}>
                <CopyTextMenuItem textToCopy={message.message} />
              </MessageMenu>
            </div>
          </div>
          <div className="mt-0.5 text-right text-xs text-gray-500">{dayjs(message.timestamp).format('HH:mm')}</div>
        </div>
      </div>
    </div>
  );
};
