import { useEffect, useState } from 'react';

import { useUsecase } from '@/common/presentation/hooks/useUsecase.ts';
import { ListenToCallEventsUsecase } from '@/features/calling/domain/usecases/ListenToCallEventsUsecase.ts';

export const useCallState = () => {
  const [callState, setCallState] = useState('');
  const [isMuted, setIsMuted] = useState(false);
  const [isOnHold, setIsOnHold] = useState(false);

  const listenToCallEventsUsecase = useUsecase(ListenToCallEventsUsecase);

  useEffect(() => {
    const cleanup = listenToCallEventsUsecase.call((event) => {
      switch (event) {
        case 'unhold':
        case 'hold':
          setIsOnHold(event === 'hold');
          break;
        case 'muted':
        case 'unmuted':
          setIsMuted(event === 'muted');
          break;
        default:
          setCallState(event);
          break;
      }
    });

    // Remove the event listener when the component that uses this unmounts.
    return () => {
      cleanup();
    };
  }, []);

  return [callState, isMuted, isOnHold];
};
