import { useUsecase } from '@/common/presentation/hooks/useUsecase.ts';
import { TranslateUsecase } from '@/features/translations/domain/usecases/TranslateUsecase.ts';

export const NoSearchResults = () => {
  const translate = useUsecase(TranslateUsecase);

  return (
    <div className="w-full rounded p-4">
      <div className="text-center font-medium text-black">{translate.call('no_search_results')}</div>
      <div className="text-center text-neutral-700">{translate.call('no_search_results_message')}</div>
    </div>
  );
};
