import { inject, injectable } from 'inversify';

import { ChatRepository } from '@/features/chat/data/repositories/ChatRepository.ts';
import { type TimelineDirection } from '@/features/chat/data/types/TimelineDirection.ts';

@injectable()
export class SetMessagesInStoreUsecase {
  constructor(@inject(ChatRepository) private chatRepository: ChatRepository) {}

  call(roomId: string, eventId?: string, direction?: TimelineDirection) {
    this.chatRepository.setMessagesInStore(roomId, eventId, direction);
  }
}
