import classNames from 'classnames';
import * as React from 'react';

import { Avatar, type AvatarProps } from '@/common/presentation/components/Avatar.tsx';

type AvatarInsertRootProps = {
  shouldShowDot?: boolean;
  isSelected?: boolean;
};

type AvatarInsertProps = AvatarProps & AvatarInsertRootProps;

export const AvatarInsert = React.forwardRef<HTMLDivElement, AvatarInsertProps>(
  ({ shouldShowDot, isSelected, ...props }, ref) => {
    return (
      <div className="group">
        <div
          className="relative mr-4 flex size-10 items-center justify-center rounded-xl rounded-tr-none bg-avatarInsert/bgColor/default group-hover:bg-avatarInsert/bgColor/hover"
          ref={ref}
          {...props}
        >
          {/* TODO: We don't have designs yet for a AvatarInsert component with an actual avatar, when we have this we probably need to change some styling based on this. */}
          <Avatar {...props} size="small" />
          {shouldShowDot && (
            <span
              className={classNames(
                'absolute -bottom-1 -right-1 h-3.5 w-3.5 rounded-full border-2 bg-activityIndicator/bgColor group-hover:border-avatarInsert/bgColor/hover',
                isSelected ? 'border-global/active/selected' : 'border-activityIndicator/borderColor',
              )}
            ></span>
          )}
        </div>
      </div>
    );
  },
);
