import { ResCollection } from '@spindle/resclient';

export class ResCollectionMock<T> extends ResCollection {
  private items: Array<T>;

  constructor(items: Array<T>) {
    super(null, '');
    this.items = items;
  }

  toArray(): Array<T> {
    return this.items;
  }

  on() {
    return this;
  }

  off() {
    return this;
  }
}
