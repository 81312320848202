import { faCheck, faCopy } from '@awesome.me/kit-368b0118d2/icons/classic/regular';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import { type MouseEvent, useState } from 'react';

import { DropdownMenuItem } from '@/common/presentation/components/DropdownMenu.tsx';
import { useUsecase } from '@/common/presentation/hooks/useUsecase.ts';
import { TranslateUsecase } from '@/features/translations/domain/usecases/TranslateUsecase.ts';

type Props = {
  textToCopy: string;
};

/**
 * DropdownMenuItem that copies the text to the clipboard when clicked.
 */
export const CopyTextMenuItem = ({ textToCopy }: Props) => {
  const [isCopied, setIsCopied] = useState(false);
  const translate = useUsecase(TranslateUsecase);

  const handler = (e: MouseEvent) => {
    // Prevent the dropdown from closing otherwise the user won't see the "Copied!" label.
    e.preventDefault();

    setIsCopied(false);
    navigator.clipboard.writeText(textToCopy).then(() => setIsCopied(true));
  };

  return (
    <DropdownMenuItem onClick={handler} className="cursor-pointer">
      <div className="flex w-full items-center gap-2">
        <FontAwesomeIcon icon={faCopy} className={classNames('size-4', isCopied && 'text-global/confirmation')} />
        <span className={classNames(isCopied && 'text-global/confirmation')}>
          {isCopied ? translate.call('copied') : translate.call('copy_text')}
        </span>
        {isCopied && (
          <FontAwesomeIcon icon={faCheck} className={classNames('size-4', isCopied && 'text-global/confirmation')} />
        )}
      </div>
    </DropdownMenuItem>
  );
};
