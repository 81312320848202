import { inject, injectable } from 'inversify';

import { type ContactModel } from '@/features/contactbook/data/models/ContactModel.ts';
import { ContactBookRepository } from '@/features/contactbook/data/repositories/ContactBookRepository.ts';

@injectable()
export class UpdateContactUsecase {
  constructor(@inject(ContactBookRepository) private contactBookRepository: ContactBookRepository) {}

  async call(contactId: string, data: ContactModel) {
    return this.contactBookRepository.updateContact(contactId, data);
  }
}
