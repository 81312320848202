import { type ReactNode } from 'react';

type ActionButtonProps = {
  onClick?: () => void;
  children?: ReactNode;
};

export const ActionButton = ({ onClick, children }: ActionButtonProps) => {
  return (
    <button
      className="h-10 rounded-md border-2 border-gray-100 bg-white shadow-md hover:border-gray-200"
      onClick={onClick}
    >
      {children}
    </button>
  );
};
