import { EventEmitter } from 'eventemitter3';
import { inject, injectable } from 'inversify';
import { type RTCSession, type RTCSessionEventMap } from 'jssip/lib/RTCSession';

import { SipRepository } from '@/features/calling/data/repositories/SipRepository.ts';

type Events = {
  callEvent: (event: keyof RTCSessionEventMap) => void;
};

@injectable()
export class CallingRepository extends EventEmitter<Events> {
  progress: string | null;
  session?: RTCSession;

  constructor(@inject(SipRepository) private sipRepository: SipRepository) {
    super();
    this.progress = null;
  }

  private createSessionListeners() {
    const allEvents: Array<keyof RTCSessionEventMap> = [
      'accepted',
      'confirmed',
      'ended',
      'failed',
      'progress',
      'connecting',
      'sending',
      'muted',
      'unmuted',
      'hold',
      'unhold',
    ];

    allEvents.forEach((event) => {
      this.session?.on(event, () => {
        this.emit('callEvent', event);
      });
    });
  }

  call(telephoneNumber: string) {
    if (this.sipRepository.isConnected) {
      this.session = this.sipRepository.connection?.call(telephoneNumber, {
        mediaConstraints: { video: false, audio: true },
      });

      if (this.session) {
        this.createSessionListeners();
      }
    } else {
      throw new Error(`No connection, status of server is: ${this.sipRepository.status}`);
    }
  }

  endCall() {
    this.session?.terminate();
    this.session?.removeAllListeners();
  }

  mute() {
    this.session?.mute();
  }

  unmute() {
    this.session?.unmute();
  }

  isMuted() {
    // Only return the audio state, as we don't support video calls.
    return Boolean(this.session?.isMuted().audio);
  }

  sendDtmf(dtmf: string, duration: number) {
    this.session?.sendDTMF(dtmf, { duration });
  }

  hold() {
    this.session?.hold();
  }

  unhold() {
    this.session?.unhold();
  }

  isOnHold() {
    return this.session?.isOnHold().local;
  }
}
