import { useUsecase } from '@/common/presentation/hooks/useUsecase.ts';
import { TranslateUsecase } from '@/features/translations/domain/usecases/TranslateUsecase.ts';

export const WhatsAppLogo = () => {
  const translate = useUsecase(TranslateUsecase);

  return (
    <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
      <title>{translate.call('whatsapp_logo')}</title>
      <desc>{translate.call('whatsapp_logo_description')}</desc>
      <path
        d="M0 24.8733L1.75261 18.4866C0.669869 16.613 0.101141 14.4876 0.103756 12.3247C0.106611 5.52878 5.64767 0 12.4568 0C15.7611 0.00167569 18.8625 1.28509 21.195 3.6148C23.5275 5.94452 24.8109 9.0412 24.8097 12.3346C24.8067 19.13 19.2648 24.6598 12.4566 24.6598H12.4513C10.384 24.659 8.35273 24.1414 6.54841 23.1594L0 24.8733Z"
        fill="white"
      />
      <path
        d="M11.5045 3.19964e-05C5.16102 3.19964e-05 0.00226854 5.15666 1.19492e-05 11.4949C-0.00311211 13.6593 0.6064 15.7803 1.75808 17.6128L2.0317 18.0478L0.86974 22.2896L5.22233 21.1484L5.64262 21.3973C7.40784 22.4449 9.43181 22.9989 11.4958 23H11.5002C17.8387 23 22.9975 17.8428 22.9999 11.5042C23.0047 9.99336 22.7098 8.49662 22.1321 7.10059C21.5545 5.70457 20.7057 4.43698 19.6348 3.37122C18.5699 2.29963 17.3029 1.44991 15.9074 0.87124C14.5118 0.292573 13.0153 -0.00354347 11.5045 3.19964e-05Z"
        fill="url(#paint0_linear_7895_3122)"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M8.55958 5.60386C8.30139 5.02964 8.02971 5.01801 7.78445 5.00807L7.12417 5C6.89446 5 6.52122 5.08629 6.2057 5.43146C5.89018 5.77663 5 6.61085 5 8.30762C5 10.0044 6.23437 11.6439 6.40637 11.8743C6.57837 12.1047 8.78929 15.6976 12.2906 17.08C15.2002 18.2288 15.7923 18.0003 16.4241 17.9429C17.0559 17.8855 18.4621 17.1087 18.7489 16.3034C19.0358 15.498 19.036 14.8081 18.95 14.6638C18.864 14.5195 18.6343 14.4338 18.2895 14.2612C17.9447 14.0886 16.2515 13.2544 15.9358 13.1392C15.6201 13.0241 15.3906 12.9668 15.1607 13.312C14.9308 13.6572 14.2714 14.4336 14.0704 14.6638C13.8693 14.894 13.6687 14.9229 13.3239 14.7505C12.9792 14.5781 11.87 14.2138 10.5539 13.0389C9.52995 12.1247 8.83876 10.9958 8.63753 10.6508C8.43629 10.3059 8.61616 10.119 8.78892 9.94718C8.94349 9.7926 9.13329 9.54442 9.30586 9.34313C9.47842 9.14185 9.5352 8.99796 9.64986 8.76816C9.76453 8.53836 9.70739 8.33651 9.6212 8.16412C9.53501 7.99172 8.86574 6.28594 8.55958 5.60386Z"
        fill="white"
      />
      <defs>
        <linearGradient
          id="paint0_linear_7895_3122"
          x1="11.2659"
          y1="1.38025"
          x2="11.3824"
          y2="21.0352"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#57D163" />
          <stop offset="1" stop-color="#23B33A" />
        </linearGradient>
      </defs>
    </svg>
  );
};
