import { inject, injectable } from 'inversify';

import { ChatRepository } from '@/features/chat/data/repositories/ChatRepository.ts';

@injectable()
export class SendTypingEventUsecase {
  constructor(@inject(ChatRepository) private chatRepository: ChatRepository) {}

  async call(roomId: string, isTyping: boolean) {
    this.chatRepository.sendTypingEvent(roomId, isTyping);
  }
}
