import { AvatarInsert } from '@/common/presentation/components/AvatarInsert.tsx';
import { Card, CardContent, CardDescription, CardTitle } from '@/common/presentation/components/Card.tsx';
import { Skeleton } from '@/common/presentation/components/Skeleton.tsx';
import { useUsecase } from '@/common/presentation/hooks/useUsecase.ts';
import { FormatDateToDateString } from '@/common/presentation/usecases/FormatDateToDateString.ts';
import { cn } from '@/common/presentation/utils.ts';
import { type TypingMember } from '@/features/chat/domain/entities/RoomEntity.ts';
import { createTypingMembersString } from '@/features/chat/utils/createTypingMembersString.ts';

type Props = {
  name: string;
  description: string;
  timestamp: number;
  typingMembers: Array<TypingMember>;
  shouldShowDot: boolean;
  isLoading: boolean;
  isSelected: boolean;
};

export const InboxListCard = ({
  name,
  description,
  timestamp,
  shouldShowDot,
  isLoading,
  isSelected,
  typingMembers,
}: Props) => {
  const formattedDate = useUsecase(FormatDateToDateString).call(new Date(timestamp));

  return (
    <Card
      className={cn(
        'group flex rounded-none border-b border-l-4 p-4 shadow-none last:border-b hover:bg-layout/interactive/bgColor/hover',
        isSelected
          ? 'border-l-primary bg-layout/interactive/bgColor/selected'
          : 'border-l-transparent bg-layout/neutral/bgColor',
      )}
    >
      {isLoading ? (
        <Skeleton className="mr-4 size-10 rounded-xl rounded-tr-none" />
      ) : (
        <AvatarInsert shouldShowDot={shouldShowDot} isSelected={isSelected} />
      )}
      <CardContent className="flex-1 space-y-1 overflow-hidden p-0">
        <CardTitle className="flex justify-between text-base">
          <span className="flex-1">{isLoading ? <Skeleton className="h-4" /> : name}</span>
          <span className="ml-2 text-sm font-normal">
            {isLoading ? <Skeleton className="h-4 w-10" /> : formattedDate}
          </span>
        </CardTitle>
        <CardDescription className="flex-1 truncate">
          {isLoading ? (
            <Skeleton className="h-4" />
          ) : typingMembers.length > 0 ? (
            <span className="italic">{createTypingMembersString(typingMembers)}</span>
          ) : (
            description
          )}
        </CardDescription>
      </CardContent>
    </Card>
  );
};
