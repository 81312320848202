import { inject, injectable } from 'inversify';

import { ChatRepository } from '@/features/chat/data/repositories/ChatRepository.ts';

@injectable()
export class StartChatConnectionUsecase {
  constructor(@inject(ChatRepository) private chatRepository: ChatRepository) {}

  async call() {
    await this.chatRepository.init();
  }
}
