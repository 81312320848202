import { inject, injectable } from 'inversify';

import { CallingRepository } from '@/features/calling/data/repositories/CallingRepository.ts';
import { DTMF_DURATION } from '@/features/calling/domain/constants/DtmfConstants.ts';

@injectable()
export class SendDtmfUsecase {
  constructor(@inject(CallingRepository) private callingRepository: CallingRepository) {}

  call(dtmf: string) {
    this.callingRepository.sendDtmf(dtmf, DTMF_DURATION);
  }
}
