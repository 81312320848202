// The reflect-metadata allows Typescript to use the decorators which is needed for our dependency injection library.
import 'reflect-metadata';

import { FlagProvider, type IConfig } from '@unleash/proxy-client-react';
import { StrictMode } from 'react';
import { createRoot } from 'react-dom/client';
import 'react-loading-skeleton/dist/skeleton.css';

import { App } from '@/App.tsx';
import { ContainerContext } from '@/common/contexts/ContainerContext.ts';
import { container } from '@/container.ts';

const config: IConfig = {
  url: import.meta.env.VITE_FEATURE_FLAGS_API_URL,
  clientKey: import.meta.env.VITE_FEATURE_FLAGS_API_CLIENT_KEY,
  refreshInterval: 60,
  appName: 'conversations',
};

const element = document.getElementById('root')!;

createRoot(element).render(
  /*
   * https://react.dev/reference/react/StrictMode#strictmode
   * Your components will re-render an extra time to find bugs caused by impure rendering.
   * Your components will re-run Effects an extra time to find bugs caused by missing Effect cleanup.
   * Your components will be checked for usage of deprecated APIs.
   */
  <StrictMode>
    <FlagProvider config={config}>
      <ContainerContext.Provider value={container}>
        <App />
      </ContainerContext.Provider>
    </FlagProvider>
  </StrictMode>,
);
