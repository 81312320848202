import { faGearComplex } from '@awesome.me/kit-368b0118d2/icons/classic/regular';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';

import { HeaderNavigation } from '@/common/presentation/components/HeaderNavigation.tsx';
import { ProfileMenu } from '@/common/presentation/components/ProfileMenu.tsx';
import { useUsecase } from '@/common/presentation/hooks/useUsecase.ts';
import { SearchBar } from '@/features/search/presentation/components/SearchBar.tsx';
import { TranslateUsecase } from '@/features/translations/domain/usecases/TranslateUsecase.ts';

export const Header = () => {
  const translate = useUsecase(TranslateUsecase);
  return (
    <header className="flex items-center border-b p-4">
      <HeaderNavigation />
      <div className="w-96">
        <SearchBar />
      </div>
      <div className="flex flex-1 justify-end">
        <div className="mr-2 flex items-center">
          <ProfileMenu />
        </div>
        <Link
          to={'settings/profile'}
          aria-label={translate.call('settings')}
          className="flex size-10 cursor-pointer items-center justify-center rounded-full hover:text-primary"
        >
          <FontAwesomeIcon icon={faGearComplex} className="size-5" />
        </Link>
      </div>
    </header>
  );
};
