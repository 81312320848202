import { useFlag } from '@unleash/proxy-client-react';
import { type ReactNode } from 'react';

import { useUsecase } from '@/common/presentation/hooks/useUsecase.ts';
import { TranslateUsecase } from '@/features/translations/domain/usecases/TranslateUsecase.ts';

type Props = {
  children?: ReactNode;
  name: string;
};

export const FeatureFlag = ({ children, name }: Props) => {
  const isActive = useFlag(name);
  const isLocalDev = import.meta.env.MODE === 'development';
  const translate = useUsecase(TranslateUsecase);

  if (isLocalDev || isActive) {
    return <div>{children}</div>;
  }

  return <div>{translate.call('forbidden')}</div>;
};
