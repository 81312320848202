import { inject, injectable } from 'inversify';

import { ContactBookRepository } from '@/features/contactbook/data/repositories/ContactBookRepository.ts';

@injectable()
export class DeleteContactUsecase {
  constructor(@inject(ContactBookRepository) private contactBookRepository: ContactBookRepository) {}

  async call(contactUuid: string) {
    return await this.contactBookRepository.deleteContact(contactUuid);
  }
}
