import { inject, injectable } from 'inversify';

import { ChatRepository } from '@/features/chat/data/repositories/ChatRepository.ts';

@injectable()
export class GetMessagesStoreUsecase {
  constructor(@inject(ChatRepository) private chatRepository: ChatRepository) {}

  call() {
    return this.chatRepository.getMessagesStore();
  }
}
