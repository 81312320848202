import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { useEffect } from 'react';
import { RouterProvider, createBrowserRouter } from 'react-router-dom';

import { ConnectToResgateUsecase } from '@/common/domain/usecases/ConnectToResgateUsecase.ts';
import { FeatureFlag } from '@/common/presentation/components/FeatureFlag.tsx';
import { useUsecase } from '@/common/presentation/hooks/useUsecase.ts';
import { GetAuthStoreUsecase } from '@/features/auth/domain/usecases/GetAuthStoreUsecase.ts';
import { LoginPage } from '@/features/auth/presentation/pages/LoginPage.tsx';
import { ProtectedRoutes } from '@/features/auth/presentation/pages/ProtectedRoutes.tsx';
import { StartSipConnectionUsecase } from '@/features/calling/domain/usecases/StartSipConnectionUsecase.ts';
import { CallingPage } from '@/features/calling/presentation/pages/CallingPage.tsx';
import { StartChatConnectionUsecase } from '@/features/chat/domain/usecases/StartChatConnectionUsecase.ts';
import { ChatRoutes } from '@/features/chat/presentation/pages/ChatRoutes.tsx';
import { InboxLayout } from '@/features/chat/presentation/pages/InboxLayout.tsx';
import { ContactList } from '@/features/contactbook/presentation/components/ContactList.tsx';
import { CreateContact } from '@/features/contactbook/presentation/components/CreateContact.tsx';
import { ContactBook } from '@/features/contactbook/presentation/pages/ContactBook.tsx';
import { ConnectToReportingUsecase } from '@/features/report/domain/usecases/ConnectToReportingUsecase.ts';
import { SetIdentityUsecase } from '@/features/report/domain/usecases/SetIdentityUsecase.ts';
import { SearchOverview } from '@/features/search/presentation/pages/SearchOverview.tsx';
import { Settings } from '@/features/settings/presentation/pages/Settings.tsx';
import { SettingsIntegrations } from '@/features/settings/presentation/pages/SettingsIntegrations.tsx';
import { SettingsNotifications } from '@/features/settings/presentation/pages/SettingsNotifications.tsx';
import { SettingsProfile } from '@/features/settings/presentation/pages/SettingsProfile.tsx';

const queryClient = new QueryClient();

// Here we define the routes for the application.
const router = createBrowserRouter([
  {
    path: '/login',
    element: <LoginPage />,
  },
  // The ProtectedRoutes component protects all children elements from unauthenticated access.
  // If the user is not authenticated, it will redirect to the login page.
  {
    path: '/',
    element: <ProtectedRoutes />,
    children: [
      {
        // The ChatRoutes component is used to protect certain routes that require authentication
        // to the Matrix service. It checks if the chat is initialized and then renders the route.
        path: 'inbox',
        element: <ChatRoutes />,
        children: [
          {
            path: ':roomId?/:eventId?',
            element: <InboxLayout />,
          },
        ],
      },
      {
        path: 'search',
        element: <SearchOverview />,
      },
      {
        path: 'calling',
        element: (
          <FeatureFlag name="conversations_calling-is-active">
            <CallingPage />
          </FeatureFlag>
        ),
      },
      {
        path: '/settings',
        element: <Settings />,
        children: [
          {
            path: '/settings/profile',
            element: <SettingsProfile />,
          },
          {
            path: '/settings/integrations',
            element: <SettingsIntegrations />,
          },
          {
            path: '/settings/notifications',
            element: <SettingsNotifications />,
          },
        ],
      },
      {
        path: '/contactbook',
        element: (
          <FeatureFlag name={'conversations_contactbook-is-active'}>
            <ContactBook />
          </FeatureFlag>
        ),
        children: [
          {
            path: '/contactbook/:contactId?',
            element: <CreateContact />,
          },
          {
            path: '/contactbook/list',
            element: <ContactList />,
          },
        ],
      },
    ],
  },
]);

export const App = () => {
  const connectToReporting = useUsecase(ConnectToReportingUsecase);
  const setIdentity = useUsecase(SetIdentityUsecase);
  const authStore = useUsecase(GetAuthStoreUsecase).call();
  const startChatConnection = useUsecase(StartChatConnectionUsecase);
  const startSipConnectionUsecase = useUsecase(StartSipConnectionUsecase);
  const connectToResgate = useUsecase(ConnectToResgateUsecase);
  const { isAuthenticated, uuid } = authStore();

  // When developing the useEffects will be triggered twice because of React Strict mode.
  // This is no issue as each of these functions already check if they are already connected.
  useEffect(() => {
    if (isAuthenticated) {
      startChatConnection.call();
      startSipConnectionUsecase.call();
      connectToReporting.call().then(() => {
        setIdentity.call(uuid);
      });
      connectToResgate.call();
    } else {
      connectToReporting.call().then(() => {
        // Setup anonymous reporting.
        setIdentity.call();
      });
    }
  }, [
    uuid,
    connectToReporting,
    isAuthenticated,
    setIdentity,
    startChatConnection,
    startSipConnectionUsecase,
    connectToResgate,
  ]);

  return (
    <QueryClientProvider client={queryClient}>
      <RouterProvider router={router} />
    </QueryClientProvider>
  );
};
