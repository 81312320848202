import { useLocalStorage } from '@uidotdev/usehooks';

import { Dialog, type DialogProps } from '@/common/presentation/components/Dialog.tsx';
import { useUsecase } from '@/common/presentation/hooks/useUsecase.ts';
import { TranslateUsecase } from '@/features/translations/domain/usecases/TranslateUsecase.ts';

export const FirstNoteDialog = ({ trigger, ...props }: Partial<DialogProps>) => {
  const [shouldNotShowAgain, setShouldNotShowAgain] = useLocalStorage('should_not_show_first_note_dialog', false);
  const translate = useUsecase(TranslateUsecase);

  return (
    <Dialog {...props} trigger={!shouldNotShowAgain && trigger} title={translate.call('first_note_dialog_title')}>
      <p>{translate.call('first_note_dialog_content_1')}</p>
      <p>
        {translate.call('first_note_dialog_content_2_1')} <b>/note</b> {translate.call('first_note_dialog_content_2_2')}
      </p>
      <div className="flex gap-2 py-4">
        {/* TODO: replace with Checkbox component */}
        <input id="dont-show-again" type="checkbox" onChange={(e) => setShouldNotShowAgain(e.target.checked)} />
        <label htmlFor="dont-show-again">{translate.call('dont_show_this_again')}</label>
      </div>
    </Dialog>
  );
};
