import { useState } from 'react';

import { useUsecase } from '@/common/presentation/hooks/useUsecase.ts';
import { EndCallUsecase } from '@/features/calling/domain/usecases/EndCallUsecase.ts';
import { MakeOutgoingCallUsecase } from '@/features/calling/domain/usecases/MakeOutgoingCallUsecase.ts';
import { ToggleMuteUsecase } from '@/features/calling/domain/usecases/ToggleMuteUsecase.ts';
import { ToggleOnHoldUsecase } from '@/features/calling/domain/usecases/ToggleOnHoldUsecase.ts';
import { ActionButton } from '@/features/calling/presentation/components/ActionButton.tsx';
import { useCallState } from '@/features/calling/presentation/hooks/useCallState.ts';
import { useSendDtmf } from '@/features/calling/presentation/hooks/useSendDtmf.ts';

const keyPad = ['1', '2', '3', '4', '5', '6', '7', '8', '9', '*', '0', '#'];

export const Dialer = () => {
  const [dialerInput, setDialerInput] = useState('');
  const [callState, isMuted, isOnHold] = useCallState();
  const sendDtmf = useSendDtmf();

  const endCallUsecase = useUsecase(EndCallUsecase);
  const makeOutgoingCallUsecase = useUsecase(MakeOutgoingCallUsecase);
  const toggleMuteUsecase = useUsecase(ToggleMuteUsecase);
  const toggleOnHoldUsecase = useUsecase(ToggleOnHoldUsecase);

  const handleDialerInput = (key: string) => {
    if (callState === 'confirmed') {
      sendDtmf(key);
    } else {
      setDialerInput((prev) => prev + key);
    }
  };

  return (
    <div className="m-4 w-[300px] rounded-md bg-gray-100 p-4">
      <input
        className="mb-4 h-10 w-full rounded-md border px-4 shadow-sm"
        type="text"
        value={dialerInput}
        onChange={(event) => setDialerInput(event.target.value)}
      />
      <div className="mb-4 grid grid-cols-3 gap-3 text-lg">
        {keyPad.map((key) => (
          <ActionButton key={key} onClick={() => handleDialerInput(key)}>
            {key}
          </ActionButton>
        ))}
      </div>
      <div className="flex flex-col gap-3">
        <ActionButton onClick={() => makeOutgoingCallUsecase.call(dialerInput)}>Call</ActionButton>
        <ActionButton onClick={() => endCallUsecase.call()}>End Call</ActionButton>
        <ActionButton>Answer</ActionButton>
        <ActionButton>Decline</ActionButton>
        <ActionButton>Transfer</ActionButton>
        <ActionButton onClick={() => toggleMuteUsecase.call()}>{isMuted ? 'Unmute' : 'Mute'}</ActionButton>
        <ActionButton onClick={() => toggleOnHoldUsecase.call()}>{isOnHold ? 'Resume' : 'Hold'}</ActionButton>
      </div>
      <div>
        <div>Call status: {callState}</div>
        <div>Muted: {isMuted ? 'Muted' : 'Not muted'}</div>
        <div>On Hold: {isOnHold ? 'Yes' : 'No'}</div>
      </div>
    </div>
  );
};
