import { faCircleUser } from '@awesome.me/kit-368b0118d2/icons/classic/regular';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as AvatarPrimitive from '@radix-ui/react-avatar';
import { type VariantProps, cva } from 'class-variance-authority';
import * as React from 'react';

import { cn } from '@/common/presentation/utils.ts';

const avatarVariants = cva('relative flex shrink-0 overflow-hidden rounded-full', {
  variants: {
    size: {
      small: 'size-4',
      medium: 'size-6',
      large: 'size-10',
    },
  },
  defaultVariants: {
    size: 'large',
  },
});

const avatarFallbackVariants = cva('', {
  variants: {
    size: {
      small: 'size-4',
      medium: 'size-6',
      large: 'size-10',
    },
  },
  defaultVariants: {
    size: 'large',
  },
});

export type AvatarProps = React.ComponentProps<typeof AvatarPrimitive.Root> &
  React.ComponentProps<typeof AvatarPrimitive.Image> &
  React.ComponentProps<typeof AvatarPrimitive.Fallback> &
  VariantProps<typeof avatarVariants> &
  VariantProps<typeof avatarFallbackVariants>;

const AvatarRoot = React.forwardRef<React.ElementRef<typeof AvatarPrimitive.Root>, AvatarProps>(
  ({ className, size, ...props }, ref) => (
    <AvatarPrimitive.Root ref={ref} className={cn(avatarVariants({ size }), className)} {...props} />
  ),
);
AvatarRoot.displayName = AvatarPrimitive.Root.displayName;

const AvatarImage = React.forwardRef<
  React.ElementRef<typeof AvatarPrimitive.Image>,
  React.ComponentPropsWithoutRef<typeof AvatarPrimitive.Image>
>(({ className, ...props }, ref) => (
  <AvatarPrimitive.Image ref={ref} className={cn('aspect-square h-full w-full', className)} {...props} />
));
AvatarImage.displayName = AvatarPrimitive.Image.displayName;

const AvatarFallback = React.forwardRef<React.ElementRef<typeof AvatarPrimitive.Fallback>, AvatarProps>(
  ({ className, size, ...props }, ref) => (
    <AvatarPrimitive.Fallback
      ref={ref}
      className={cn('flex h-full w-full items-center justify-center', className)}
      {...props}
    >
      <FontAwesomeIcon
        icon={faCircleUser}
        className={cn(
          avatarFallbackVariants({ size }),
          'text-icon/neutral/fillColor/default group-hover:text-icon/neutral/fillColor/hover',
        )}
      />
    </AvatarPrimitive.Fallback>
  ),
);
AvatarFallback.displayName = AvatarPrimitive.Fallback.displayName;

export const Avatar = (props: AvatarProps) => {
  return (
    <AvatarRoot {...props}>
      <AvatarImage {...props} />
      <AvatarFallback {...props} />
    </AvatarRoot>
  );
};
Avatar.displayName = 'Avatar';
