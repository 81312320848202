import { inject, injectable } from 'inversify';

import { SipRepository } from '@/features/calling/data/repositories/SipRepository.ts';

@injectable()
export class StartSipConnectionUsecase {
  constructor(@inject(SipRepository) private sipRepository: SipRepository) {}

  async call() {
    return await this.sipRepository.connect();
  }
}
