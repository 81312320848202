import { inject, injectable } from 'inversify';

import { ContactBookRepository } from '@/features/contactbook/data/repositories/ContactBookRepository.ts';

@injectable()
export class GetContactsUsecase {
  constructor(@inject(ContactBookRepository) private contactBookRepository: ContactBookRepository) {}

  async call() {
    return this.contactBookRepository.getContacts();
  }
}
