import { type DeepPartial } from '@/common/data/types/DeepPartial.ts';
import { type ContactModel } from '@/features/contactbook/data/models/ContactModel.ts';

export const transformContactModelToContactRequestModel = (contactRequest: ContactModel): DeepPartial<ContactModel> => {
  return {
    given_name: contactRequest.given_name,
    family_name: contactRequest.family_name,
    company_name: contactRequest.company_name,
    phone_numbers: contactRequest.phone_numbers.map((phoneNumber) => ({
      phone_number_flat: phoneNumber.phone_number_flat,
    })),
  };
};
