import { faCircleNodes, faMessageExclamation, faUser } from '@awesome.me/kit-368b0118d2/icons/classic/regular';
import { Outlet } from 'react-router-dom';

import { Sidemenu } from '@/common/presentation/components/Sidemenu.tsx';
import { useUsecase } from '@/common/presentation/hooks/useUsecase.ts';
import { Page } from '@/common/presentation/pages/Page.tsx';
import { TranslateUsecase } from '@/features/translations/domain/usecases/TranslateUsecase.ts';

export const Settings = () => {
  const translate = useUsecase(TranslateUsecase);

  const menuItems = [
    { label: translate.call('profile'), href: '/settings/profile', icon: faUser },
    { label: translate.call('integrations'), href: '/settings/integrations', icon: faCircleNodes },
    { label: translate.call('notifications'), href: '/settings/notifications', icon: faMessageExclamation },
  ];

  return (
    <Page title={translate.call('settings')}>
      <div className="bg-gray-100 px-8 py-4">
        <div className="space-y-0.5">
          <h2 className="text-2xl font-medium">{translate.call('settings')}</h2>
          <p className="text-neutral-700">{translate.call('settings_page_description')}</p>
        </div>
        <div data-orientation="horizontal" role="none" className="my-6 h-[1px] w-full shrink-0 border"></div>
        <div className="flex">
          <Sidemenu items={menuItems} />
          <div className="ml-4 flex-1 rounded-md bg-white p-4 shadow">
            <div className="flex items-start">
              <div className="grid gap-4 xl:grid-cols-2">
                <Outlet />
              </div>
            </div>
          </div>
        </div>
      </div>
    </Page>
  );
};
