import { inject, injectable } from 'inversify';

import { ChatRepository } from '@/features/chat/data/repositories/ChatRepository.ts';

@injectable()
export class SetRoomMessageDraftUsecase {
  constructor(@inject(ChatRepository) private chatRepository: ChatRepository) {}

  call(roomId: string, draft: string) {
    this.chatRepository.setRoomMessageDraft(roomId, draft);
  }
}
