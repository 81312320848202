import { inject, injectable } from 'inversify';

import { ChatRepository } from '@/features/chat/data/repositories/ChatRepository.ts';

@injectable()
export class GetRoomsStoreUsecase {
  constructor(@inject(ChatRepository) private chatRepository: ChatRepository) {}

  call() {
    const roomsStore = this.chatRepository.getRoomsStore();
    const { rooms, isInitialized } = roomsStore();
    rooms.sort((a, b) => b.lastMessageTimestamp - a.lastMessageTimestamp);

    return { rooms, isInitialized };
  }
}
