import { inject, injectable } from 'inversify';
import { type RTCSessionEventMap } from 'jssip/lib/RTCSession';

import { CallingRepository } from '@/features/calling/data/repositories/CallingRepository.ts';

@injectable()
export class ListenToCallEventsUsecase {
  constructor(@inject(CallingRepository) private callingRepository: CallingRepository) {}

  // Method to listen for call events
  call(handler: (state: keyof RTCSessionEventMap) => void) {
    // Attach the event listener
    this.callingRepository.on('callEvent', handler);

    // Return a cleanup function to remove the listener
    return () => {
      this.callingRepository.off('callEvent', handler);
    };
  }
}
