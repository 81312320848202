import axios, { AxiosHeaders } from 'axios';
import { inject, injectable } from 'inversify';

import { AuthStore } from '@/features/auth/data/stores/AuthStore.ts';

export type ApiRequestOptions = {
  url: string;
  method: 'GET' | 'POST' | 'PUT' | 'DELETE';
  data?: object;
  shouldUseBearerToken?: boolean;
};

@injectable()
export abstract class Api {
  baseUrl = '';

  constructor(@inject(AuthStore) protected authStore: AuthStore) {}

  protected async request<T>({ url, method, data, shouldUseBearerToken = true }: ApiRequestOptions) {
    const headers = new AxiosHeaders();
    const { token, email } = this.authStore.getState();

    if (token) {
      if (shouldUseBearerToken) {
        headers.set('Authorization', `Bearer ${token}`);
      } else {
        headers.set('Authorization', `Token ${email}:${token}`);
      }
    }

    return await axios.request<T>({
      url: `${this.baseUrl}${url}`,
      method,
      data,
      headers,
    });
  }
}
