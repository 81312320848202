import { useMutation, useQuery } from '@tanstack/react-query';
import { useEffect } from 'react';
import { type SubmitHandler, useFieldArray, useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';

import { useUsecase } from '@/common/presentation/hooks/useUsecase.ts';
import { type ContactModel } from '@/features/contactbook/data/models/ContactModel.ts';
import { CreateContactUsecase } from '@/features/contactbook/domain/usecases/CreateContactUsecase.ts';
import { GetContactUsecase } from '@/features/contactbook/domain/usecases/GetContactUsecase.ts';
import { UpdateContactUsecase } from '@/features/contactbook/domain/usecases/UpdateContactUsecase.ts';

export const CreateContact = () => {
  const { contactId } = useParams();
  const getContactUsecase = useUsecase(GetContactUsecase);
  const updateContactUsecase = useUsecase(UpdateContactUsecase);
  const createContactUsecase = useUsecase(CreateContactUsecase);

  const query = useQuery({
    queryKey: ['contact', contactId],
    queryFn: async () => {
      if (!contactId) {
        return Promise.reject('No contact id provided');
      }

      return await getContactUsecase.call(contactId);
    },
    enabled: !!contactId,
  });

  const form = useForm<ContactModel>({
    defaultValues: query.data || {
      phone_numbers: [{ phone_number_flat: '', phone_number_pretty: '', country_code: '' }],
    },
  });

  useEffect(() => {
    // When we receive data from the useQuery we need to reset the form so that the form state is updated.
    if (query.data) {
      form.reset(query.data);
    }
  }, [query.data, form]);

  const mutation = useMutation({
    mutationFn: async (data: ContactModel) => {
      if (contactId) {
        return await updateContactUsecase.call(contactId, data);
      } else {
        return await createContactUsecase.call(data);
      }
    },
    onSuccess: (data: ContactModel) => {
      if (contactId) {
        form.reset(data);
      } else {
        form.reset();
      }
    },
  });

  const fieldArray = useFieldArray({
    control: form.control,
    name: 'phone_numbers',
  });

  const onSubmit: SubmitHandler<ContactModel> = (data) => {
    // Filter out empty phone number fields
    data.phone_numbers = data.phone_numbers.filter((phoneNumber) => phoneNumber.phone_number_flat.trim() !== '');

    mutation.mutate(data);
  };

  if (query.isLoading) {
    return <div>Loading...</div>;
  }

  return (
    <div className="max-w-md rounded-md bg-white p-4">
      <h1 className="mb-4 text-2xl font-bold">Create Contact</h1>
      {mutation.isSuccess && <p className="text-green-500">Action succeeded</p>}
      {mutation.isError && <p className="text-red-500">Something went wrong</p>}
      {/* TODO: Replace this with a DS components and DS form component */}
      <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-4">
        <div>
          <input
            {...form.register('given_name', { required: 'First name is required' })}
            placeholder="First name"
            className="w-full rounded-md border border-gray-300 p-2"
          />
          {form.formState.errors.given_name && (
            <p className="text-red-500">{form.formState.errors.given_name.message}</p>
          )}
        </div>
        <div>
          <input
            {...form.register('family_name', { required: 'Last name is required' })}
            placeholder="Last name"
            className="w-full rounded-md border border-gray-300 p-2"
          />
          {form.formState.errors.family_name && (
            <p className="text-red-500">{form.formState.errors.family_name.message}</p>
          )}
        </div>
        <div>
          <input
            {...form.register('company_name', { required: 'Company name is required' })}
            placeholder="Company name"
            className="w-full rounded-md border border-gray-300 p-2"
          />
          {form.formState.errors.company_name && (
            <p className="text-red-500">{form.formState.errors.company_name.message}</p>
          )}
        </div>

        <h2 className="mt-4 text-xl font-semibold">Phone Numbers</h2>
        {fieldArray.fields.map((field, index) => (
          <div key={field.id} className="flex items-center space-x-2">
            <div className="w-full">
              <input
                {...form.register(`phone_numbers.${index}.phone_number_flat` as const)}
                placeholder="Phone number"
                className="w-full rounded-md border border-gray-300 p-2"
              />
            </div>
            {index > 0 && (
              <button
                type="button"
                onClick={() => fieldArray.remove(index)}
                className="rounded-md bg-red-500 p-2 text-white"
              >
                Remove
              </button>
            )}
          </div>
        ))}
        <button
          type="button"
          onClick={() => fieldArray.append({ phone_number_flat: '', phone_number_pretty: '', country_code: '' })}
          className="w-full rounded-md bg-blue-500 p-2 text-white"
        >
          Add Phone Number
        </button>

        <button type="submit" className="w-full rounded-md bg-green-500 p-2 text-white">
          Submit
        </button>
      </form>
    </div>
  );
};
