import { inject, injectable } from 'inversify';

import { type WhatsAppAuthorizeRequest } from '@/features/settings/data/models/WhatsAppAuthorizeRequest.ts';
import { WhatsAppRepository } from '@/features/settings/data/repositories/WhatsAppRepository.ts';

@injectable()
export class WhatsAppAuthorizeRequestUsecase {
  constructor(@inject(WhatsAppRepository) private whatsAppRepository: WhatsAppRepository) {}

  async call(data: WhatsAppAuthorizeRequest) {
    return await this.whatsAppRepository.authorize(data);
  }
}
