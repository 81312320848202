import { injectable } from 'inversify';

import { HolodeckApi } from '@/common/data/api/HolodeckApi.ts';
import { type WhatsAppAuthorizeRequest } from '@/features/settings/data/models/WhatsAppAuthorizeRequest.ts';

@injectable()
export class WhatsAppBridgeApi extends HolodeckApi {
  service = '/matrix-whatsapp-bridge';

  async authorize(data: WhatsAppAuthorizeRequest) {
    return this.request({
      url: `/authorize`,
      method: 'POST',
      data,
    });
  }
}
