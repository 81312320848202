import { useQuery } from '@tanstack/react-query';
import classNames from 'classnames';
import { useState } from 'react';
import { useSearchParams } from 'react-router-dom';

import { Tabs, TabsContent, TabsList, TabsTrigger } from '@/common/presentation/components/Tabs.tsx';
import { useUsecase } from '@/common/presentation/hooks/useUsecase.ts';
import { Page } from '@/common/presentation/pages/Page.tsx';
import { GetChatSearchResultsUsecase } from '@/features/search/domain/usecases/GetChatSearchResultsUsecase.ts';
import { GetRoomSearchResultsUsecase } from '@/features/search/domain/usecases/GetRoomSearchResultsUsecase.ts';
import { NoSearchResults } from '@/features/search/presentation/components/NoSearchResults.tsx';
import { SearchResultItem } from '@/features/search/presentation/components/SearchResultItem.tsx';
import { generateMockChatSearchResults } from '@/features/search/presentation/utils/generateMockChatSearchResults.ts';
import { generateMockRoomSearchResults } from '@/features/search/presentation/utils/generateMockRoomSearchResults.ts';
import { TranslateUsecase } from '@/features/translations/domain/usecases/TranslateUsecase.ts';

export const SearchOverview = () => {
  const translate = useUsecase(TranslateUsecase);
  const [searchParams] = useSearchParams();
  const searchQuery = searchParams.get('q') || '';
  const [totalChatResults, setTotalChatResults] = useState(0);
  const [totalRoomResults, setTotalRoomsResults] = useState(0);
  const fetchChatSearchResults = useUsecase(GetChatSearchResultsUsecase);
  const fetchRoomSearchResults = useUsecase(GetRoomSearchResultsUsecase);
  const tabStyle = classNames(
    'mr-4 rounded bg-opacity-10 px-4 py-2 text-base data-[state=active]:bg-primary-lightest data-[state=active]:text-primary',
  );

  const {
    data: chatData,
    error: chatError,
    isPlaceholderData: isChatPlaceholderData,
  } = useQuery({
    queryKey: [searchQuery, 'searchChatQuery'],
    placeholderData: {
      chats: {
        results: generateMockChatSearchResults(),
        total_results: 0,
      },
    },
    queryFn: async () => {
      const searchResults = await fetchChatSearchResults.call(searchQuery);
      setTotalChatResults(searchResults.chats.total_results);
      return searchResults;
    },
  });

  const {
    data: roomData,
    error: roomError,
    isPlaceholderData: isRoomPlaceholderData,
  } = useQuery({
    queryKey: [searchQuery, 'searchRoomQuery'],
    placeholderData: {
      rooms: {
        results: generateMockRoomSearchResults(),
        total_results: 0,
      },
    },
    queryFn: async () => {
      const chatSearchResults = await fetchRoomSearchResults.call(searchQuery);
      setTotalRoomsResults(chatSearchResults.rooms.total_results);
      return chatSearchResults;
    },
  });

  // TODO: AA-50: Add a no-search results page
  return (
    <Page
      title={translate.call('search_results_found_for_query', {
        total_results: (totalChatResults + totalRoomResults).toString(),
        search_query: searchQuery,
      })}
    >
      <main className="overflow-y-scroll p-4">
        <Tabs defaultValue="chat">
          <TabsList className="mb-2">
            <TabsTrigger value="chat" className={tabStyle}>
              {translate.call('messages')}&nbsp;({totalChatResults})
            </TabsTrigger>
            <TabsTrigger value="people" className={tabStyle}>
              {translate.call('people')}&nbsp;({totalRoomResults})
            </TabsTrigger>
          </TabsList>
          <TabsContent
            value="chat"
            className="h-full flex-col items-start bg-white data-[state-active]:flex data-[state-inactive]:hidden"
          >
            {chatData && chatData.chats.results.length !== 0 ? (
              chatData.chats.results.map((item) => (
                <SearchResultItem
                  key={item.event.id}
                  link={`/inbox/${item.room.id}/${item.event.id}`}
                  title={item.room.name}
                  searchQuery={searchQuery}
                  isLoaded={!isChatPlaceholderData}
                  tag={item.sender.name}
                  description={item.message}
                  timestamp={item.event.timestamp}
                />
              )) // When there are no chat results, we display a message to the user
            ) : chatError || (chatData && chatData.chats.results.length === 0) ? (
              <NoSearchResults />
            ) : null}
          </TabsContent>
          <TabsContent
            value="people"
            className="h-full flex-col items-start bg-white data-[state-active]:flex data-[state-inactive]:hidden"
          >
            {roomData && roomData.rooms.results.length !== 0 ? (
              roomData.rooms.results.map((item) => (
                <SearchResultItem
                  key={item.event.id}
                  link={`/inbox/${item.room.id}`}
                  title={item.room.name}
                  searchQuery={searchQuery}
                  isLoaded={!isRoomPlaceholderData}
                />
              ))
            ) : // When there are no room results, we display a message to the user
            roomError || (roomData && roomData.rooms.results.length === 0) ? (
              <NoSearchResults />
            ) : null}
          </TabsContent>
        </Tabs>
      </main>
    </Page>
  );
};
