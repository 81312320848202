import { inject, injectable } from 'inversify';

import { ChatRepository } from '@/features/chat/data/repositories/ChatRepository.ts';
import { type MessageEntity } from '@/features/chat/domain/entities/MessageEntity.ts';

@injectable()
export class MarkMessageAsReadUsecase {
  constructor(@inject(ChatRepository) private chatRepository: ChatRepository) {}

  async call(message: MessageEntity) {
    await this.chatRepository.markMessageAsRead(message);
  }
}
