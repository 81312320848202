import { inject, injectable } from 'inversify';

import { CallingRepository } from '@/features/calling/data/repositories/CallingRepository.ts';

@injectable()
export class ToggleMuteUsecase {
  constructor(@inject(CallingRepository) private callingRepository: CallingRepository) {}

  call() {
    if (this.callingRepository.isMuted()) {
      this.callingRepository.unmute();
    } else {
      this.callingRepository.mute();
    }
  }
}
