import { faArrowLeft, faArrowRight } from '@awesome.me/kit-368b0118d2/icons/classic/regular';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import { useLocation, useNavigate } from 'react-router-dom';

import { useUsecase } from '@/common/presentation/hooks/useUsecase.ts';
import { TranslateUsecase } from '@/features/translations/domain/usecases/TranslateUsecase.ts';

export const HeaderNavigation = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const translate = useUsecase(TranslateUsecase);

  /**
   * Since the history object is not being exposed with react-router-dom v6
   * This is currently the recommended way by react-router to check if
   * we can navigate back within our application (instead of checking
   * window.history.state.idx). The location will be set to default
   * if there is no history for the app yet (and we can't navigate back).
   * URLs:
   * - https://github.com/remix-run/react-router/issues/10964#issuecomment-1782159284
   * - https://stackoverflow.com/questions/65948671/how-to-go-back-to-previous-route-in-react-router-dom-v6
   */
  const canGoBackwards = location.key !== 'default' && location.state?.from !== '/login';

  /**
   * To check if we can go forwards we would prefer to use the Navigation API,
   * specifically https://developer.mozilla.org/en-US/docs/Web/API/Navigation/canGoForward
   * but it is not available in all browsers yet.
   * For the unsupported browsers (Firefox and Safari) we simply return true since
   * we can't be determined without creating and holding our own custom history stack.
   * Within those unsupported browsers, the forward and backwards button will always
   * styled to be enabled, irrespective of the actual state.
   *
   * Currently the navigation object is not within the TypeScript types for the window object
   * since it's only available on Chrome and Edge. Once it's added to one more browser engine
   * it will be added to the TypeScript types. Ignoring the error for now.
   * URL: https://github.com/microsoft/TypeScript-DOM-lib-generator/issues/1531
   */

  // @ts-expect-error - See description above.
  const hasSupportForNavigationApi = typeof window.navigation !== 'undefined';

  const canGoForwardsCheck = () => {
    if (hasSupportForNavigationApi) {
      // @ts-expect-error - See description above.
      return window.navigation.canGoForward as boolean;
    }

    return true;
  };

  const canGoForwards = canGoForwardsCheck();

  const navigateBackwards = () => {
    if (!canGoBackwards) return;

    navigate(-1);
  };

  const navigateForwards = () => {
    navigate(1);
  };

  /**
   * The buttons are styled based on the support for the Navigation API.
   * Some browsers (Firefox and Safari) don't support the Navigation API yet.
   * For those browsers, we want to show the buttons to never be disabled,
   * since we can't make a distinction if a forward button is active.
   */
  const buttonClassNames = classNames(
    'inline-flex size-10 items-center justify-center rounded-md hover:bg-neutral-100 disabled:opacity-50 hover:disabled:bg-transparent',
    {
      '!cursor-pointer !opacity-100 hover:disabled:!bg-neutral-100': !hasSupportForNavigationApi,
    },
  );

  return (
    <div className="ml-1">
      <button
        onClick={navigateBackwards}
        disabled={!canGoBackwards}
        className={buttonClassNames}
        aria-label={translate.call('navigate_back')}
      >
        <FontAwesomeIcon icon={faArrowLeft} />
      </button>
      <button
        onClick={navigateForwards}
        disabled={!canGoForwards}
        className={buttonClassNames}
        aria-label={translate.call('navigate_forward')}
      >
        <FontAwesomeIcon icon={faArrowRight} />
      </button>
    </div>
  );
};
