import { inject, injectable } from 'inversify';

import { CallingRepository } from '@/features/calling/data/repositories/CallingRepository.ts';

@injectable()
export class EndCallUsecase {
  constructor(@inject(CallingRepository) private callingRepository: CallingRepository) {}

  call() {
    return this.callingRepository.endCall();
  }
}
