import { EventType, type MatrixEvent } from 'matrix-js-sdk';

/**
 * Check if the event does not have an age property.
 * If not, this means that it's a new message.
 */
export const isNewMessage = (event: MatrixEvent) => {
  const eventType = event.getType();
  // Checking if the event has an age property makes sure we don't push events for old messages.
  return eventType === EventType.RoomMessage && !Object.prototype.hasOwnProperty.call(event.event, 'age');
};
