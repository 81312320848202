import { create } from 'zustand';

type State = {
  userId: string;
  userName: string;
  isInitialized: boolean;
  accessToken: string;
};

export const chatStore = create<State>(() => ({
  accessToken: '',
  isInitialized: false,
  userId: '',
  userName: '',
}));
