import { faMagnifyingGlass } from '@awesome.me/kit-368b0118d2/icons/classic/regular';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import { createRef, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';

import { useUsecase } from '@/common/presentation/hooks/useUsecase.ts';
import { TranslateUsecase } from '@/features/translations/domain/usecases/TranslateUsecase.ts';

export const SearchBar = () => {
  const [isButtonFocused, setIsButtonFocused] = useState(false);
  const [existingSearchQuery] = useSearchParams();
  const queryParam = existingSearchQuery.get('q');
  const [searchQuery, setSearchQuery] = useState(queryParam ? queryParam : '');
  const searchInput = createRef<HTMLInputElement>();
  const searchButton = createRef<HTMLButtonElement>();
  const isSearchQueryNotEqualToQueryParam = searchQuery !== queryParam;
  const translate = useUsecase(TranslateUsecase);

  const navigate = useNavigate();

  const inputClasses = classNames(
    'peer h-10 w-full rounded-md bg-neutral-100 px-4 py-2 pr-16 text-base font-medium text-black text-neutral-700 placeholder-neutral-500 shadow-[inset_0px_1px_4px_rgba(0,0,0,0.1)] focus:placeholder-transparent focus:outline-none focus:ring-2 focus:ring-primary',
    {
      'ring-2 ring-primary': isSearchQueryNotEqualToQueryParam && (isButtonFocused || searchQuery.length > 0),
    },
  );

  const buttonClasses = classNames('absolute right-0 top-0 h-full cursor-default rounded-r-md px-4', {
    'fill-[#535353]': searchQuery.length === 0,
    'cursor-pointer bg-primary bg-opacity-10 fill-primary hover:bg-primary hover:fill-white focus:bg-primary focus:fill-white':
      isSearchQueryNotEqualToQueryParam && searchQuery.length > 0,
  });

  const performSearch = () => {
    navigate(`/search?q=${searchQuery}`);
    searchInput.current?.blur();
    searchButton.current?.blur();
  };

  return (
    <div className="relative mr-4 pl-4">
      <form
        onSubmit={(evt) => {
          evt.preventDefault();
          performSearch();
        }}
      >
        <input
          type="search"
          aria-label={translate.call('search_label')}
          aria-placeholder={translate.call('search_placeholder')}
          tabIndex={0}
          onChange={(evt) => setSearchQuery(evt.target.value)}
          placeholder={translate.call('search_placeholder')}
          defaultValue={searchQuery}
          className={inputClasses}
          ref={searchInput}
        />
        <button
          aria-label={translate.call('search_for_label', { search_query: searchQuery })}
          tabIndex={searchQuery.length > 0 ? 0 : -1}
          onFocus={() => setIsButtonFocused(true)}
          onBlur={() => setIsButtonFocused(false)}
          className={buttonClasses}
          ref={searchButton}
          disabled={searchQuery.length === 0 || !isSearchQueryNotEqualToQueryParam}
        >
          <FontAwesomeIcon icon={faMagnifyingGlass} className="size-4" />
        </button>
      </form>
    </div>
  );
};
