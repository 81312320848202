import { inject, injectable } from 'inversify';
import { type UA } from 'jssip';

import { sipWebsocket } from '@/features/calling/data/api/SipWebsocket.ts';
import { type SipAccountModel } from '@/features/calling/data/models/SipAccountModel.ts';
import { VoipAccountRepository } from '@/features/calling/data/repositories/VoipAccountRepository.ts';

@injectable()
export class SipRepository {
  connection?: UA;

  constructor(@inject(VoipAccountRepository) private voipAccountRepository: VoipAccountRepository) {}

  async connect() {
    if (this.isConnected) return;

    const sipAccount = await this.getVoipAccountAsSipAccount();
    this.connection = await sipWebsocket.connect(sipAccount);

    if (!this.connection) {
      return Promise.reject(new Error('Failed to create a connection to the SIP server'));
    }

    return new Promise<UA>((resolve, reject) => {
      this.connection?.on('connected', () => {
        if (this.connection) {
          resolve(this.connection);
        }
      });

      this.connection?.on('disconnected', () => {
        this.connection?.removeAllListeners();
        reject();
      });

      this.connection?.start();
    });
  }

  disconnect() {
    if (this.isConnected) {
      this.connection?.stop();
    }
  }

  private async getVoipAccountAsSipAccount(): Promise<SipAccountModel> {
    const account = await this.voipAccountRepository.getSelectedWebphoneVoipAccount();

    return {
      id: account.data.account_id,
      password: account.data.password,
      uri: `sip:${account.data.account_id}@${import.meta.env.VITE_SIP_REALM}`,
    };
  }

  get isConnected() {
    return Boolean(this.connection?.isConnected());
  }

  get status() {
    return this.connection?.status;
  }
}
