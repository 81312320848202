import dayjs from 'dayjs';
import { inject, injectable } from 'inversify';

import { TranslateUsecase } from '@/features/translations/domain/usecases/TranslateUsecase.ts';

/**
 * Formats a given date into a relative datetime string representation.
 * The format varies based on how recent the date is:
 * - If the date is today, it returns "Today at HH:mm".
 * - If the date is yesterday, it returns "Yesterday at HH:mm".
 * - If the date is within the last week, it returns the day of the week (e.g., "Monday") at HH:mm.
 * - Otherwise, it returns the date in 'MMM Do, YYYY' format at HH:mm.
 */

@injectable()
export class FormatDateToRelativeDateTimeString {
  constructor(@inject(TranslateUsecase) private translateUsecase: TranslateUsecase) {}

  call(date: Date): string {
    let shownDate = dayjs(date).format('MMM Do, YYYY');
    const shownTime = dayjs(date).format('HH:mm');
    const parsedDate = dayjs(date);
    const now = dayjs();
    const yesterday = now.subtract(1, 'day');
    const weekAgo = now.subtract(1, 'week');
    if (parsedDate.isAfter(weekAgo)) {
      const lowerCaseDay = dayjs(date).format('dddd').toLowerCase();
      shownDate = this.translateUsecase.call(lowerCaseDay);
    }
    if (parsedDate.isSame(now, 'day')) {
      shownDate = this.translateUsecase.call('today');
    }
    if (parsedDate.isSame(yesterday, 'day')) {
      shownDate = this.translateUsecase.call('yesterday');
    }

    return this.translateUsecase.call('relative_date_time', { date: shownDate, time: shownTime });
  }
}
