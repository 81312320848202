import dayjs from 'dayjs';

import { useUsecase } from '@/common/presentation/hooks/useUsecase.ts';
import { GetRoomUsecase } from '@/features/chat/domain/usecases/GetRoomUsecase.ts';
import { TranslateUsecase } from '@/features/translations/domain/usecases/TranslateUsecase.ts';

type Props = {
  roomId: string;
};

export const ChatHeader = ({ roomId }: Props) => {
  const getRoomUsecase = useUsecase(GetRoomUsecase);
  const room = getRoomUsecase.call(roomId);
  const translate = useUsecase(TranslateUsecase);

  return (
    <div className="flex h-16 items-center text-lg font-medium text-gray-700">
      <span className="flex-1 font-semibold">{room.name}</span>
      <span>
        {translate.call('started_on')}&nbsp;
        {dayjs(room.lastMessageTimestamp).format('DD/MM/YYYY')}
      </span>
    </div>
  );
};
