/// <reference types="facebook-js-sdk" />
import { useMutation } from '@tanstack/react-query';
import { useEffect, useState } from 'react';

import { Button } from '@/common/presentation/components/Button.tsx';
import { LoadingSpinner } from '@/common/presentation/components/LoadingSpinner.tsx';
import { useUsecase } from '@/common/presentation/hooks/useUsecase.ts';
import { WhatsAppAuthorizeRequestUsecase } from '@/features/settings/domain/usecases/WhatsAppAuthorizeUsecase.ts';
import { WhatsAppLogo } from '@/features/settings/presentation/components/WhatsAppLogo.tsx';
import { TranslateUsecase } from '@/features/translations/domain/usecases/TranslateUsecase.ts';

export const WhatsAppIntegration = () => {
  const translate = useUsecase(TranslateUsecase);
  const whatsAppAuthorizeRequest = useUsecase(WhatsAppAuthorizeRequestUsecase);
  const [authorizationCode, setAuthorizationCode] = useState('');
  const [wabaId, setWabaId] = useState('');

  /**
   * Attaches a listener to the window function which is called when the Facebook SDK is loaded.
   * Loads the Facebook SDK asynchronously.
   */
  const loadFacebookSdk = () => {
    // Attach a listener, which is called when the Facebook SDK is loaded.
    window.fbAsyncInit = function () {
      FB.init({
        appId: '944094597397832',
        autoLogAppEvents: true,
        xfbml: true,
        version: 'v21.0',
      });
    };

    const id = 'facebook-jssdk';
    let js: HTMLScriptElement | null = document.getElementById(id) as HTMLScriptElement;
    const fjs: Element = document.getElementsByTagName('script')[0];
    if (js) return;
    js = document.createElement('script') as HTMLScriptElement;
    js.id = id;
    js.src = 'https://connect.facebook.net/en_US/sdk.js';
    fjs.parentNode?.insertBefore(js, fjs);
  };

  /**
   * Once the dialog is closed we receive a message from the Facebook SDK
   * this can contain multiple types of messages, so we define multiple
   * cases to handle each one of them (FINISH, ERROR, CANCEL)
   */
  const setupFacebookDialogMessageListener = () => {
    window.addEventListener('message', (event) => {
      // Ignore messages from other origins
      if (event.origin === null) return;
      if (!event.origin.endsWith('facebook.com')) return;

      try {
        const data = JSON.parse(event.data);
        if (data.type === 'WA_EMBEDDED_SIGNUP') {
          switch (data.event) {
            // If the user completes the Embedded Signup flow
            // we have access to the waba_id
            case 'FINISH_ONLY_WABA':
              setWabaId(data.data.waba_id);
              break;
          }
        }
      } catch {
        // We're receiving different types of messages from Facebook, and some
        // of them are not in the JSON format. We can safely ignore them.
      }
    });
  };

  const authorizeQuery = useMutation({
    mutationFn: async () => {
      const data = {
        exchange_token: authorizationCode,
        whatsapp_business_account_id: wabaId,
      };

      await whatsAppAuthorizeRequest.call(data);
    },
    // TODO: #AA-165 Call the connected endpoint to verify the connection
    // and display the connected status.
    onSuccess: () => {
      // Reset the values
      setAuthorizationCode('');
      setWabaId('');
    },
    onError: (error) => {
      throw error;
    },
  });

  useEffect(() => {
    if (authorizationCode && wabaId) {
      // Since the useEffect is triggered on all state changes, we need to make sure
      // that we only call the mutation when it's idle so it's called once at a time.
      if (authorizeQuery.isIdle) {
        authorizeQuery.mutate();
      }
    }
  }, [authorizeQuery, authorizationCode, wabaId]);

  useEffect(() => {
    loadFacebookSdk();
    setupFacebookDialogMessageListener();
  }, []);

  const fbLoginCallback: facebook.FacebookEventCallback<facebook.FacebookEventType> = (
    response: facebook.StatusResponse,
  ) => {
    if (response.authResponse && response.authResponse.code) {
      setAuthorizationCode(response.authResponse.code);
    }
  };

  const launchWhatsAppSetup = () => {
    FB.login(fbLoginCallback, {
      config_id: '1672723813265970',
      response_type: 'code',
      override_default_response_type: true,
      extras: {
        featureType: 'only_waba_sharing',
        sessionInfoVersion: 3,
      },
    });
  };

  return (
    <div className="rounded-md border bg-white">
      <div className="p-4">
        <div className="mb-2 flex items-center py-2">
          <WhatsAppLogo />
          <div className="ml-2 flex-1">
            <h3 className="text-xl font-medium leading-none">WhatsApp Business</h3>
            <span className="text-xs text-neutral-500">whatsapp.com</span>
          </div>
          <div className="ml-2">
            {authorizeQuery.isPending && (
              <div className="flex items-center rounded-full bg-orange-100 px-3 py-1.5 font-semibold text-orange-700">
                <LoadingSpinner className="mr-2 h-4 w-4 text-orange-700" />
                <span>{translate.call('connecting')}</span>
              </div>
            )}
          </div>
          {/* TODO: With connection endpoint 
          <div className="ml-2">
            {!authorizeQuery.isPending && (
              <div className="flex items-center rounded-full bg-green-100 px-3 py-1.5 font-semibold text-green-700">
                <FontAwesomeIcon icon={faBadgeCheck} className="mr-2 size-4 text-green-700" />
                <span>{translate.call('connected')}</span>
              </div>
            )}
          </div>
          */}
        </div>
        <span className="leading-6 text-neutral-500">{translate.call('whatsapp_integration_description')}</span>
      </div>
      <div className="mt-4 flex justify-end border-t bg-neutral-100 p-4">
        <Button type="button" variant="neutral" onClick={() => launchWhatsAppSetup()}>
          {translate.call('whatsapp_integration_button_setup')}
        </Button>
      </div>
    </div>
  );
};
