import { type ResCollection } from '@spindle/resclient';
import { useQuery } from '@tanstack/react-query';
import { useEffect, useState } from 'react';

type CollectionUsecase = {
  call: () => Promise<ResCollection>;
};

type AddEvent<T> = {
  idx: number;
  value: T;
};

type RemoveEvent = {
  idx: number;
};

export const useResgateCollection = <T>(
  queryKey: string,
  collectionUsecase: CollectionUsecase,
  placeholderData: ResCollection,
) => {
  const [models, setModels] = useState<Array<T>>([]);

  const { data, isLoading } = useQuery({
    queryKey: [queryKey],
    placeholderData: placeholderData,
    queryFn: async () => {
      return await collectionUsecase.call();
    },
  });

  useEffect(() => {
    if (!data) return;

    const handleAdd = ({ value }: AddEvent<T>) => {
      setModels((prevData) => [...prevData, value]);
    };

    const handleRemove = ({ idx }: RemoveEvent) => {
      setModels((prevData) => prevData.filter((_, index) => index !== idx));
    };

    setModels(data.toArray() as Array<T>);

    data.on('add', handleAdd);
    data.on('remove', handleRemove);

    return () => {
      data.off('add', handleAdd);
      data.off('remove', handleRemove);
    };
  }, [data]);

  return { isLoading, models };
};
