import { useUsecase } from '@/common/presentation/hooks/useUsecase.ts';
import { WhatsAppIntegration } from '@/features/settings/presentation/components/WhatsAppIntegration.tsx';
import { TranslateUsecase } from '@/features/translations/domain/usecases/TranslateUsecase.ts';

export const SettingsIntegrations = () => {
  const translate = useUsecase(TranslateUsecase);
  return (
    <div>
      <div className="mb-4 max-w-2xl border-b pb-4">
        <h3 className="mb-1 text-xl font-medium">{translate.call('integrations')}</h3>
        <p className="text-neutral-500">{translate.call('settings_integrations_description')}</p>
      </div>
      <WhatsAppIntegration />
    </div>
  );
};
