import { useCallback, useEffect, useRef, useState } from 'react';

import { Button } from '@/common/presentation/components/Button.tsx';
import { useUsecase } from '@/common/presentation/hooks/useUsecase.ts';
import { FormatDateToDateString } from '@/common/presentation/usecases/FormatDateToDateString.ts';
import { type TimelineDirection } from '@/features/chat/data/types/TimelineDirection.ts';
import { GetMessagesStoreUsecase } from '@/features/chat/domain/usecases/GetMessagesStoreUsecase.ts';
import { GetRoomUsecase } from '@/features/chat/domain/usecases/GetRoomUsecase.ts';
import { SetMessagesInStoreUsecase } from '@/features/chat/domain/usecases/SetMessagesInStoreUsecase.ts';
import { ChatInput } from '@/features/chat/presentation/components/ChatInput.tsx';
import { FirstNoteDialog } from '@/features/chat/presentation/components/FirstNoteDialog.tsx';
import { InfiniteScroll } from '@/features/chat/presentation/components/InfiniteScroll.tsx';
import { Message } from '@/features/chat/presentation/components/Message.tsx';
import { MessageDateBubble } from '@/features/chat/presentation/components/MessageDateBubble.tsx';
import { createTypingMembersString } from '@/features/chat/utils/createTypingMembersString.ts';

type Props = {
  roomId: string;
  urlEventId?: string;
};

export const ChatView = ({ roomId, urlEventId }: Props) => {
  const formatDateToDateString = useUsecase(FormatDateToDateString);

  const setMessagesInStoreUsecase = useUsecase(SetMessagesInStoreUsecase);
  const getMessagesStoreUsecase = useUsecase(GetMessagesStoreUsecase);
  const getRoomUsecase = useUsecase(GetRoomUsecase);
  const room = getRoomUsecase.call(roomId);
  const messagesStore = getMessagesStoreUsecase.call();
  const { messagesGroupedByDate, hasMoreBackwards, hasMoreForwards } = messagesStore();
  const amountOfMessages = Object.keys(messagesGroupedByDate).length;

  const rootRef = useRef<HTMLDivElement | null>(null);
  const scrollableRootRef = useRef<HTMLDivElement | null>(null);
  const [hasScrolledInitially, setHasScrolledInitially] = useState(false);
  const messagesEndRef = useRef<HTMLDivElement | null>(null);
  const messageTopRef = useRef<HTMLDivElement | null>(null);
  const eventRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    setMessagesInStoreUsecase.call(roomId, urlEventId);
  }, [roomId, setMessagesInStoreUsecase, urlEventId]);

  useEffect(() => {
    if (messagesGroupedByDate && amountOfMessages > 0 && !hasScrolledInitially) {
      if (urlEventId) {
        eventRef.current?.scrollIntoView({ behavior: 'instant', block: 'center' });
      } else {
        messagesEndRef.current?.scrollIntoView({ behavior: 'instant' });
      }
      setHasScrolledInitially(true);
    }
  }, [urlEventId, messagesGroupedByDate, amountOfMessages, hasScrolledInitially]);

  useEffect(() => {
    setHasScrolledInitially(false);
  }, [roomId]);

  const handleLoadMore = useCallback(
    async (direction: TimelineDirection) => {
      if (!hasMoreForwards && direction === 'forwards') return;
      if (!hasMoreBackwards && direction === 'backwards') return;

      const scrollableNode = scrollableRootRef.current;

      const oldNode = messageTopRef.current;
      await setMessagesInStoreUsecase.call(roomId, urlEventId, direction);

      if (direction === 'backwards') {
        scrollableNode?.scrollTo({
          top: oldNode?.offsetTop,
        });
      }
    },
    [hasMoreForwards, hasMoreBackwards, setMessagesInStoreUsecase, roomId, urlEventId],
  );

  return (
    <div className="flex flex-1 flex-col overflow-hidden">
      <div
        className="relative flex-1 overflow-y-scroll rounded-md border bg-gray-100 p-4 pt-0 shadow-inner"
        ref={rootRef}
      >
        <InfiniteScroll
          rootRef={rootRef}
          handleTopIntersection={async () => handleLoadMore('backwards')}
          handleBottomIntersection={async () => handleLoadMore('forwards')}
        >
          {Object.entries(messagesGroupedByDate).map(([date, messages], mapIndex) => (
            <div key={date} className="flex flex-col">
              <div className="sticky top-0 flex justify-center py-4">
                <MessageDateBubble text={formatDateToDateString.call(new Date(date), true)} />
              </div>
              {messages.map((message, index) => (
                <div
                  key={message.eventId}
                  ref={(node) => {
                    if (message.eventId === urlEventId) {
                      eventRef.current = node;
                    }
                    if (index === 0 && mapIndex === 0) {
                      messageTopRef.current = node;
                    }
                  }}
                  className="mb-4 last:mb-0"
                >
                  <Message
                    urlEventId={urlEventId}
                    key={message.eventId}
                    message={message}
                    hasSameSenderAsPreviousMessage={messages[index - 1]?.senderId === message.senderId}
                    userName={message.senderDisplayName}
                    inboxRef={rootRef}
                  />
                </div>
              ))}
            </div>
          ))}
        </InfiniteScroll>
        <div ref={messagesEndRef} />
      </div>
      {room.typingMembers.length > 0 && (
        <div className="relative">
          <div className="absolute bottom-2 right-2">
            <div className="text-sm text-gray-500">{createTypingMembersString(room.typingMembers)}</div>
          </div>
        </div>
      )}
      <FirstNoteDialog
        trigger={
          <Button variant="neutral">Trigger note dialog (replace with actual little note button, see CI-826)</Button>
        }
      />
      <ChatInput roomId={roomId} messagesEndRef={messagesEndRef} />
    </div>
  );
};
