import { type IconProp } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as NavigationMenuPrimitive from '@radix-ui/react-navigation-menu';
import * as React from 'react';
import { NavLink, type NavLinkProps } from 'react-router-dom';

import { cn } from '@/common/presentation/utils.ts';

/*
 * See NavigationMenu (Radix component) docs here: https://www.radix-ui.com/primitives/docs/components/navigation-menu
 */

const NavigationMenu = React.forwardRef<
  React.ElementRef<typeof NavigationMenuPrimitive.Root>,
  React.ComponentPropsWithoutRef<typeof NavigationMenuPrimitive.Root>
>(({ className, children, ...props }, ref) => {
  return (
    <NavigationMenuPrimitive.Root
      ref={ref}
      className={cn(
        'flex w-full flex-1 justify-center border-t border-layout/neutral/borderColor/default bg-layout/neutral/bgColor px-8 py-4 md:border-r md:border-t-transparent md:px-4 md:py-6',
        className,
      )}
      orientation="vertical"
      {...props}
    >
      {children}
    </NavigationMenuPrimitive.Root>
  );
});
NavigationMenu.displayName = NavigationMenuPrimitive.Root.displayName;

const NavigationMenuList = React.forwardRef<
  React.ElementRef<typeof NavigationMenuPrimitive.List>,
  React.ComponentPropsWithoutRef<typeof NavigationMenuPrimitive.List>
>(({ className, ...props }, ref) => (
  <NavigationMenuPrimitive.List ref={ref} className={cn('flex gap-12 md:flex-col md:gap-8', className)} {...props} />
));
NavigationMenuList.displayName = NavigationMenuPrimitive.List.displayName;

const NavigationMenuItem = NavigationMenuPrimitive.Item;

type NavigationMenuLinkProps = NavLinkProps & {
  icon: IconProp;
  to: string;
  content: React.ReactNode;
  isDisabled?: boolean;
};

// https://www.radix-ui.com/primitives/docs/components/navigation-menu#with-client-side-routing
const NavigationMenuLink = React.forwardRef<
  React.ElementRef<typeof NavigationMenuPrimitive.Link>,
  NavigationMenuLinkProps
>(({ content, className, icon, isDisabled, ...props }, ref) => (
  <NavigationMenuPrimitive.Link
    ref={ref}
    asChild
    className={cn(
      'group/link text-text/neutral/color/secondary/default hover:text-text/neutral/color/secondary/hover',
      isDisabled && 'pointer-events-none cursor-not-allowed opacity-55',
      className,
    )}
    aria-disabled={isDisabled}
  >
    <NavLink {...props}>
      {({ isActive }) => (
        <div className={cn('flex flex-col items-center gap-1')}>
          <div
            className={cn(
              'rounded-2xl px-4 py-1 text-icon/neutral/fillColor/default group-hover/link:bg-navigationItem/bgColor/hover group-hover/link:text-controls/neutral/iconFillColor/hover',
              isActive && 'bg-navigationItem/bgColor/selected text-icon/onSoftBrandBg/fillColor',
            )}
          >
            <FontAwesomeIcon icon={icon} />
          </div>
          <div
            className={cn(
              'text-icon/neutral/fillColor/default group-hover/link:text-controls/neutral/iconFillColor/hover',
              isActive && 'text-text/brand/default',
            )}
          >
            {content}
          </div>
        </div>
      )}
    </NavLink>
  </NavigationMenuPrimitive.Link>
));
NavigationMenuLink.displayName = NavigationMenuPrimitive.Link.displayName;

type SideBarProps = {
  links: Array<NavigationMenuLinkProps>;
};

export const SideBar = ({ links }: SideBarProps) => (
  <NavigationMenu>
    <NavigationMenuList>
      {links.map((link) => (
        <NavigationMenuItem key={link.to}>
          <NavigationMenuLink {...link} />
        </NavigationMenuItem>
      ))}
    </NavigationMenuList>
  </NavigationMenu>
);
SideBar.displayName = 'SideBar';
