import { inject, injectable } from 'inversify';

import { CallingRepository } from '@/features/calling/data/repositories/CallingRepository.ts';

@injectable()
export class MakeOutgoingCallUsecase {
  constructor(@inject(CallingRepository) private callingRepository: CallingRepository) {}

  call(telephoneNumber: string) {
    this.callingRepository.call(telephoneNumber);
  }
}
