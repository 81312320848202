import ResClient, { type ResCollection } from '@spindle/resclient';
import { inject, injectable } from 'inversify';

import { AuthStore } from '@/features/auth/data/stores/AuthStore.ts';

type ResgateOptions = {
  shouldAuthenticate?: boolean;
};

@injectable()
export class Resgate {
  private client!: ResClient;

  constructor(@inject(AuthStore) private authStore: AuthStore) {}

  connect({ shouldAuthenticate = true }: ResgateOptions = {}) {
    this.client = new ResClient(import.meta.env.VITE_WS_RESGATE);

    if (shouldAuthenticate) {
      const { token } = this.authStore.getState();

      // Set the on connect handler to authenticate on (re)connect.
      // Await the authenticate to make sure it is resolved before
      // the subscription(s) happen to prevent a race condition.
      this.client.setOnConnect(async () => {
        await this.client.authenticate('usertoken', 'login', { token });
      });
    }
  }

  async getCollection(subject: string) {
    if (!this.client) {
      throw new Error('Resgate client not connected');
    }

    return (await this.client.get(subject)) as ResCollection;
  }
}
