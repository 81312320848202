import { injectable } from 'inversify';

import { HolodeckApi } from '@/common/data/api/HolodeckApi.ts';
import { type DeepPartial } from '@/common/data/types/DeepPartial.ts';
import { type ContactModel } from '@/features/contactbook/data/models/ContactModel.ts';

@injectable()
export class ContactBookApi extends HolodeckApi {
  service = '/contactbook';

  async getContact(clientUuid: string, contactId: string) {
    return this.request<ContactModel>({
      url: `/clients/${clientUuid}/contacts/${contactId}`,
      method: 'GET',
    });
  }

  async createContact(clientUuid: string, data: DeepPartial<ContactModel>) {
    return this.request<ContactModel>({
      url: `/clients/${clientUuid}/contacts`,
      method: 'POST',
      data,
    });
  }

  async updateContact(clientUuid: string, contactId: string, data: DeepPartial<ContactModel>) {
    return this.request<ContactModel>({
      url: `/clients/${clientUuid}/contacts/${contactId}`,
      method: 'PUT',
      data,
    });
  }

  async deleteContact(clientUuid: string, contactUuid: string) {
    return this.request({
      url: `/clients/${clientUuid}/contacts/${contactUuid}`,
      method: 'DELETE',
    });
  }
}
